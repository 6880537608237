import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   createAnnouncementRequest,
   getAnnouncementsRequest,
   getAnnouncementByIdRequest,
   deleteAnnouncementRequest,
   editAnnouncementRequest,
   AnnouncementShowStatusChangeRequest,
} from '../../api/announcementService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get announcements ROLE(ADMIN, INSTRUCTOR)
export const getAnnouncements = createAsyncThunk(
   'announcements/getAnnouncements',
   async ({ search, params }, { rejectWithValue }) => {
      try {
         const { data } = await getAnnouncementsRequest(search, params)
         return { announcements: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create announcement ROLE(ADMIN, INSTRUCTOR)
export const createAnnouncement = createAsyncThunk(
   'announcements/createAnnouncements',
   async (
      { announcement, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { data } = await createAnnouncementRequest(announcement)
         notify(data.message)

         const { params } = getState().announcements
         dispatch(getAnnouncements({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete announcement ROLE(ADMIN, INSTRUCTOR)
export const deleteAnnouncement = createAsyncThunk(
   'annoucementsSlice/deleteAnnouncement',
   async (
      { announcementId, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { data } = await deleteAnnouncementRequest(announcementId)
         notify(data.message)

         const { params } = getState().announcements
         dispatch(getAnnouncements({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update announcement ROLE(ADMIN, INSTRUCTOR)
export const editAnnouncement = createAsyncThunk(
   'announcementSlice/editAnnouncement',
   async (
      { announcement, announcementId, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { data } = await editAnnouncementRequest(
            announcementId,
            announcement
         )
         notify(data?.message)

         const { params } = getState().announcements
         dispatch(getAnnouncements({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get announcement by id ROLE(ADMIN, INSTRUCTOR)
export const getAnnouncementById = createAsyncThunk(
   'announcements/getAnnouncementById',
   async ({ announcementId }, { rejectWithValue }) => {
      try {
         const { data } = await getAnnouncementByIdRequest(announcementId)
         return {
            text: data.text,
            groups: data?.groupId ? [data?.groupId] : [0],
         }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update announcement status ROLE(ADMIN, INSTRUCTOR)
export const announcementShowStatusChange = createAsyncThunk(
   'announcementSlice/announcementShowStatus',
   async (
      { announcementId, isShow, onClose },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const { data } = await AnnouncementShowStatusChangeRequest(
            announcementId,
            isShow
         )
         notify(data.message)

         const { params } = getState().announcements
         dispatch(getAnnouncements({ params }))

         return onClose()
      } catch (error) {
         onClose()
         return rejectWithValue(getRequestError(error))
      }
   }
)
