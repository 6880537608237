// global styles

import { createTheme as createMuiTheme } from '@mui/material/styles'

export const themes = {
   light: {
      meatball: {
         main: '#343A40',
      },
      components: {
         MuiMenu: {
            defaultProps: {
               sx: {
                  //   '.MuiMenu-list': {
                  //      display: 'none !important',
                  //   },
               },
            },
         },

         MuiCssBaseline: {
            styleOverrides: {
               body: {
                  backgroundColor: '#fffff',
               },
            },
         },
      },
   },
   dark: {
      palette: {
         // primary: {
         //    main: '#ffffff',
         //    light: '#ffa500',
         // },
      },
      components: {
         MuiTextField: {
            defaultProps: {
               sx: {
                  '.MuiOutlinedInput-input': {
                     //  color: 'white',
                  },
                  '.MuiInputLabel-root': {
                     //  color: 'white',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                     //  border: 'none',
                  },
               },
            },
         },

         MuiCssBaseline: {
            styleOverrides: {
               body: {
                  backgroundColor: '#fffff',
               },
            },
         },
      },
   },
}

export const createTheme = (name) => {
   return createMuiTheme(themes[name])
}
