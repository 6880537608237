import axiosInstance from '../config/axiosInstance'

export const getEventsRequest = (search) => {
   const params = { ...search }
   delete params.view
   if (params.groupId === '0') delete params.groupId
   if (params.eventStatus === '0') delete params.eventStatus
   return axiosInstance.get('/events/', { params })
}

export const createEventRequest = (event) => {
   return axiosInstance.post('/events/', event)
}

export const updateEventRequest = (eventId, event) => {
   const updatedEvent = { ...event }

   return axiosInstance.put(`/events/${eventId}`, updatedEvent)
}

export const deleteEventRequest = (eventId) => {
   return axiosInstance.delete(`/events/${eventId}`)
}

export const cancelEventRequest = (eventId, reason) => {
   return axiosInstance.put(`/events/cancel/${eventId}?reason=${reason}`)
}

export const getEventByIdRequest = (eventId) => {
   return axiosInstance.get(`/events/${eventId}`)
}

export const getEventsByDateRequest = (date) => {
   const params = { date }
   return axiosInstance.get(`events/byDate`, { params })
}

export const publishEventRequest = (eventId) => {
   return axiosInstance.put(`/events/publish/${eventId}`)
}
