import { createSlice } from '@reduxjs/toolkit'
import {
   getAllStudentsRating,
   getCourseRating,
   getStudentRating,
} from './ratingsActions'

const initialState = {
   ratings: { lessons: [], students: [], exams: [] },
   isLoading: false,
   students: [],
   hasError: false,
   message: '',
   studentRating: {},
}

const ratingSlice = createSlice({
   name: 'ratingSlice',
   initialState,
   reducers: {
      clearRatings(state) {
         state.ratings = { lessons: [], students: [], exams: [] }
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getCourseRating.pending, (state) => {
            state.hasError = false
            state.isLoading = true
         })
         .addCase(getCourseRating.rejected, (state, { payload }) => {
            state.hasError = true
            state.isLoading = false
            state.message = payload.message
         })
         .addCase(getCourseRating.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.hasError = false
            state.ratings = payload
         })

         .addCase(getAllStudentsRating.pending, (state) => {
            state.isLoading = true
            state.hasError = false
         })
         .addCase(getAllStudentsRating.rejected, (state, { payload }) => {
            state.isLoading = false
            state.hasError = true
            state.message = payload.message
         })
         .addCase(getAllStudentsRating.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.hasError = false
            state.students = payload
         })

         .addCase(getStudentRating.pending, (state) => {
            state.isLoading = true
            state.hasError = false
         })
         .addCase(getStudentRating.rejected, (state, { payload }) => {
            state.isLoading = false
            state.hasError = true
            state.message = payload.message
         })
         .addCase(getStudentRating.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.hasError = false
            state.ratings = payload
         })
   },
})

export const { clearRatings } = ratingSlice.actions

export default ratingSlice
