import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   getTeachersRequest,
   addTeacherRequest,
   editTeacherRequest,
   deleteTeacherRequest,
   getTeacherByIdRequest,
} from '../../api/teacherService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get all teachers ROLE(ADMIN)
export const getTeachers = createAsyncThunk(
   'teachersSlice/getTeachers',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getTeachersRequest(params)
         return { teachers: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// add teacher ROLE(ADMIN)
export const addTeacher = createAsyncThunk(
   'teachersSlice/addTeacher',
   async ({ teacher, onClose }, { rejectWithValue, getState, dispatch }) => {
      try {
         const { data } = await addTeacherRequest(teacher)
         notify(data.message)

         const { params } = getState().teachers
         dispatch(getTeachers({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update teacher ROLE(ADMIN)
export const editTeacher = createAsyncThunk(
   'teachersSlice/editTeacher',
   async (
      { teacher, onClose, teacherId },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { data } = await editTeacherRequest(teacher, teacherId)
         notify(data.message)

         const { params } = getState().teachers
         dispatch(getTeachers({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete teacher ROLE(ADMIN)
export const deleteTeacher = createAsyncThunk(
   'teachersSlice/deleteTeacher',
   async ({ onClose, teacherId }, { rejectWithValue, dispatch, getState }) => {
      try {
         const { data } = await deleteTeacherRequest(teacherId)
         notify(data.message)

         const { params } = getState().teachers
         dispatch(getTeachers({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get teacher by id ROLE(ADMIN)
export const getTeacherById = createAsyncThunk(
   'teachersSlice/getTeacherById',
   async (teacherId, { rejectWithValue }) => {
      try {
         const { data } = await getTeacherByIdRequest(teacherId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
