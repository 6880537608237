import { createSlice } from '@reduxjs/toolkit'
import { addVideo, deleteVideo, editVideo, getVideos } from './videoActions'

const initialState = {
   videos: [],
   isLoading: false,
}

const VideosSlice = createSlice({
   name: 'videoSlice',
   initialState,
   reducers: {
      clearVideos(state) {
         state.videos = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getVideos.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getVideos.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getVideos.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.videos = payload
         })

         .addCase(editVideo.fulfilled, (state, { payload }) => {
            state.videos = payload
         })
         .addCase(addVideo.fulfilled, (state, { payload }) => {
            state.videos = payload
         })
         .addCase(deleteVideo.fulfilled, (state, { payload: videoId }) => {
            state.videos = state.videos.filter(
               (video) => +video.id !== +videoId
            )
         })
   },
})

export const { clearVideos } = VideosSlice.actions

export default VideosSlice
