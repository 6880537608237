import { createSlice } from '@reduxjs/toolkit'

import { getTeacherCourses, getTeacherStudents } from './teacherСoursesActions'

const initialState = {
   teacherCourses: [],
   teacherStudents: [],
   course: { group: { groupName: ' ' } },
   isLoading: false,
   count: 0,
   params: { page: 1, size: 0 },
}

const teacherCoursesSlice = createSlice({
   name: 'teacherCourseSlice',
   initialState,
   reducers: {
      clearCourses(state) {
         state.teacherCourses = []
      },
      clearCourse(state) {
         state.course = {}
      },
      clearTeacherStudents(state) {
         state.teacherStudents = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getTeacherCourses.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTeacherCourses.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTeacherCourses.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.teacherCourses = payload.courses
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(getTeacherStudents.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTeacherStudents.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTeacherStudents.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.teacherStudents = payload
         })
   },
})

export const { clearCourse, clearCourses, clearTeacherStudents } =
   teacherCoursesSlice.actions

export default teacherCoursesSlice
