import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   deleteTrashItemRequest,
   getTrashItemsRequest,
   restoreTrashItemRequest,
} from '../../api/trashService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

export const getTrashItems = createAsyncThunk(
   'trashSlice/get-trash-items',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getTrashItemsRequest(params)
         return { items: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
export const restoreTrashItem = createAsyncThunk(
   'trashSlice/restore-trash-item',
   async ({ trashId }, { rejectWithValue, dispatch, getState }) => {
      try {
         const { data } = await restoreTrashItemRequest(trashId)
         notify(data.message)

         const { params } = getState().trashItems
         return dispatch(getTrashItems({ params }))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
export const deleteTrashItem = createAsyncThunk(
   'trashSlice/delete-trash-item',
   async ({ trashId }, { rejectWithValue, dispatch, getState }) => {
      try {
         const { data } = await deleteTrashItemRequest(trashId)
         notify(data.message)

         const { params } = getState().trashItems
         return dispatch(getTrashItems({ params }))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
