import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import coursesSlice from './courses/courseSlice'
import groupsSlice from './groups/groupsSlice'
import teachersSlice from './teachers/teachersSlice'
import announcementsSlice from './announcements/announcementsSlice'
import studentSlice from './students/studentSlice'
import teacherCoursesSlice from './teacherСourses/teacherСoursesSlice'
import lessonsSlice from './lessons/lessonsSlice'
import VideosSlice from './video/videoSlice'
import presentationsSlice from './presentation/presentationSlice'
import tasksSlice from './tasks/taskSlice'
import linkSlice from './links/linkSlice'
import testSlice from './tests/testSlice'
import trashSlice from './trash/trashSlice'
import ratingsSlice from './ratings/ratingsSlice'
import notificationSlice from './notification/notificationSlice'
import { requestErrorMiddleware } from './middlewares/requestMiddleware'
import calendarSlice from './calendar/calendarSlice'

const store = configureStore({
   reducer: {
      auth: authSlice.reducer,
      courses: coursesSlice.reducer,
      groups: groupsSlice.reducer,
      teachers: teachersSlice.reducer,
      announcements: announcementsSlice.reducer,
      students: studentSlice.reducer,
      teacherCourses: teacherCoursesSlice.reducer,
      lessons: lessonsSlice.reducer,
      videos: VideosSlice.reducer,
      tasks: tasksSlice.reducer,
      links: linkSlice.reducer,
      tests: testSlice.reducer,
      presentations: presentationsSlice.reducer,
      trashItems: trashSlice.reducer,
      ratings: ratingsSlice.reducer,
      notifications: notificationSlice.reducer,
      calendar: calendarSlice.reducer,
   },
   middleware: (getDefaultMiddlewares) => [
      ...getDefaultMiddlewares(),
      requestErrorMiddleware,
   ],
})

export default store
