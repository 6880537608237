import axiosInstance from '../config/axiosInstance'
import formDataInstance from '../config/axiosInstanceFormData'

// ADMIN
export const getStudentsRequest = (queries) => {
   const params = {}
   if (queries.search) params.search = queries.search
   if (queries.studyFormat) params.studyFormat = queries.studyFormat
   if (queries.groupId) params.groupId = queries.groupId
   if (queries.page) params.page = queries.page
   if (queries.size) params.size = queries.size

   return axiosInstance.get('students', { params })
}

export const addStudentRequest = (newStudent) => {
   return axiosInstance.post('students', newStudent)
}

export const deleteStudentRequest = (studentId) => {
   return axiosInstance.delete(`students/${studentId}`)
}

export const getStudentByIdRequest = (studentId) => {
   return axiosInstance.get(`students/${studentId}`)
}

export const editStudentByIdRequest = (studentId, student) => {
   return axiosInstance.put(`students/${studentId}`, student)
}

export const importExcelRequest = (groupId, excelFile) => {
   return formDataInstance.post(`students/import/${groupId}`, excelFile)
}

export const blockStudentRequest = (studentId) => {
   return axiosInstance.put(`students/${studentId}/block`)
}

export const unblockStudentRequest = (studentId) => {
   return axiosInstance.put(`students/${studentId}/unblock`)
}

// STUDENT
export const sendStudentTaskRequest = (task, taskId) => {
   return axiosInstance.post(`/lessons/tasks/students/answers/${taskId}`, task)
}

export const updateStudentTaskRequest = (taskId, task) => {
   return axiosInstance.put(`/lessons/tasks/students/answers/${taskId}`, task)
}

export const getStudentTaskRequest = (taskId) => {
   return axiosInstance.get(`/lessons/tasks/students/answers/${taskId}`)
}

export const getStudentTaskByInstructorRequest = (taskAnswerId) => {
   return axiosInstance.get(`/lessons/tasks/answers/${taskAnswerId}`)
}
