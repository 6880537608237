import axiosInstance from '../config/axiosInstance'

// ADMIN
export const createCourseRequest = (courseData) => {
   return axiosInstance.post('courses', courseData)
}

// ADMIN STUDENT
export const getCoursesRequest = (params) => {
   return axiosInstance.get('courses', { params })
}

export const deleteCourseRequest = (courseId) => {
   return axiosInstance.delete(`courses/${courseId}`)
}

export const getCourseByIdRequest = (courseId) => {
   return axiosInstance.get(`courses/${courseId}`)
}

export const updateCourseRequest = (courseId, data) => {
   return axiosInstance.put(`courses/${courseId}`, data)
}

export const assignTeacherRequest = (ids, courseId) => {
   const params = ids.map((id) => `instructorsIds=${id}`).join('&')
   return axiosInstance.put(`courses/${courseId}/assignee?${params}`)
}

export const deleteTeacherFromCourseRequest = (courseId, teacherId) => {
   return axiosInstance.put(`courses/${courseId}/delete/${teacherId}`)
}

export const blockStudentRequest = (courseId, studentId) => {
   return axiosInstance.put(`courses/${courseId}/students/${studentId}/block`)
}

export const unblockStudentRequest = (courseId, studentId) => {
   return axiosInstance.put(`courses/${courseId}/students/${studentId}/unblock`)
}

// ADMIN, INSTRUCTOR
export const getCourseStudentsRequest = (courseId) => {
   return axiosInstance.get(`courses/${courseId}/students`)
}

export const getCourseTeachersRequest = (courseId) => {
   return axiosInstance.get(`courses/${courseId}/instructors`)
}
