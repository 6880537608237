import axiosInstance from '../config/axiosInstance'

export const getCourseRatingRequest = (courseId) => {
   return axiosInstance.get(`courses/${courseId}/students/rating`)
}

export const setRatingDistributionRequest = (courseId, values) => {
   return axiosInstance.post(`/${courseId}/rating/distribution`, values)
}

export const createExamRequest = (courseId, exam) => {
   return axiosInstance.post(`/exam/create/${courseId}`, exam)
}

export const deleteExamRequest = (examId) => {
   return axiosInstance.delete(`/exam/delete/${examId}`)
}

export const giveExamMarkRequest = (examId, studentId, mark) => {
   return axiosInstance.put(`/exam/${examId}/give/mark/${studentId}`, mark)
}

// @ROLE(STUDENT)
export const getAllStudentRatingRequest = (courseId) => {
   return axiosInstance.get(`/student/all/ratings/${courseId}`)
}

// @ROLE(STUDENT)
export const getStudentRatingRequest = (courseId) => {
   return axiosInstance.get(`/student/rating/${courseId}`)
}
