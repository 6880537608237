import axiosInstance from '../config/axiosInstance'

export const getTrashItemsRequest = (params) => {
   return axiosInstance.get('trash', { params })
}
export const restoreTrashItemRequest = (trashId) => {
   return axiosInstance.put(`trash/${trashId}/restore`)
}
export const deleteTrashItemRequest = (trashId) => {
   return axiosInstance.delete(`trash/${trashId}`)
}
