/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   cancelEventRequest,
   deleteEventRequest,
   getEventsRequest,
   updateEventRequest,
   createEventRequest,
   getEventByIdRequest,
   getEventsByDateRequest,
   publishEventRequest,
} from '../../api/calendarService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get courses ROLE(ADMIN, INSTRUCTOR)
export const getEvents = createAsyncThunk(
   'courseSlice/get-events',
   async ({ search }, { rejectWithValue }) => {
      try {
         const { data } = await getEventsRequest(search)
         return { events: data, search }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const deleteEvent = createAsyncThunk(
   'courseSlice/delete-event',
   async ({ eventId, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await deleteEventRequest(eventId)
         notify(data.message)
         onClose()
         return eventId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const updateEvent = createAsyncThunk(
   'courseSlice/update-event',
   async (
      { eventId, event, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { calendar } = getState()
         await updateEventRequest(eventId, event)
         notify('Событие успешно обновлено!')
         onClose()
         return dispatch(getEvents({ search: calendar.search }))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const cancelEvent = createAsyncThunk(
   'courseSlice/cancel-event',
   async (
      { eventId, reason, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { calendar } = getState()
         await cancelEventRequest(eventId, reason)
         notify('Событие успешно отменено!')
         onClose()
         return dispatch(getEvents({ search: calendar.search }))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const createEvent = createAsyncThunk(
   'courseSlice/create-event',
   async ({ values, onClose }, { rejectWithValue, getState, dispatch }) => {
      try {
         const { calendar } = getState()
         const { data } = await createEventRequest(values)
         notify(data.message)
         onClose()
         return dispatch(getEvents({ search: calendar.search }))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const getEventById = createAsyncThunk(
   'courseSlice/get-event-by-id',
   async ({ eventId }, { rejectWithValue }) => {
      try {
         const { data } = await getEventByIdRequest(eventId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const getEventsByDate = createAsyncThunk(
   'courseSlice/get-event-by-date',
   async ({ date }, { rejectWithValue }) => {
      try {
         const { data } = await getEventsByDateRequest(date)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const publishEvent = createAsyncThunk(
   'courseSlice/pubish-event',
   async ({ eventId }, { rejectWithValue }) => {
      try {
         const { data } = await publishEventRequest(eventId)
         notify(data.message)
         return eventId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
