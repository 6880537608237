import useEnqueueSnackbar from './useEnqueueSnackbar'

let snackbarRef
const SnackbarUtilsConfigurator = () => {
   snackbarRef = useEnqueueSnackbar()
   return null
}

export const notify = (msg, variant = 'success') => snackbarRef(msg, variant)

export default SnackbarUtilsConfigurator
