import { createSlice } from '@reduxjs/toolkit'
import {
   addStudent,
   deleteStudent,
   editStudent,
   getStudents,
   importExcelStudents,
} from './studentActions'

const initialState = {
   students: [],
   studentsSearchData: {
      search: '',
      studyFormat: '',
      groupId: '',
   },
   count: 0,
   isLoading: false,
   message: '',
   excelStudents: [],

   params: { page: 1, size: 0 },
}
const studentsSlice = createSlice({
   name: 'studentSlice',
   initialState,
   reducers: {
      onChangeFilterOptions: (state, { payload }) => {
         const { name, value } = payload
         state.studentsSearchData[name] = value
      },
      applyFiltering: (state, { payload }) => {
         state.studentsSearchData = {
            ...state.studentsSearchData,
            ...payload,
         }
      },
      resetFilterOptions: (state) => {
         state.studentsSearchData = { search: '' }
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getStudents.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getStudents.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getStudents.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.students = payload.students
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(addStudent.fulfilled, (state, { payload }) => {
            state.students = payload
         })
         .addCase(deleteStudent.fulfilled, (state, { payload }) => {
            state.students = state.students.filter(
               ({ studentId }) => studentId !== +payload
            )
         })
         .addCase(editStudent.fulfilled, (state, { payload }) => {
            state.students = payload
         })

         .addCase(importExcelStudents.rejected, (state, { payload }) => {
            state.message = payload
         })
   },
})

export const studentsActions = studentsSlice.actions

export default studentsSlice
