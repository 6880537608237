import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   blockTestAnswersRequest,
   createTestRequest,
   deleteTestRequest,
   getStudentAnswerByStudentRequest,
   getStudentAnswerRequest,
   getTestAnswersRequest,
   getTestByIdRequest,
   getTestsRequest,
   getTestAsStudentRequest,
   passTestRequest,
   updateTestRequest,
} from '../../api/testService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { t } from '../../config/i18nextConfig'
import { getRequestError } from '../../utils/helpers/requestHelper'
import { convertStudentTestResults } from '../../utils/helpers/testHelper'

export const getTestTime = (testTime) => {
   const splittedTime = testTime.split(':')
   return t('test_form.test_time', {
      hours: splittedTime[0],
      minutes: splittedTime[1],
   })
}

// get all tests ROLE(INSTRUCTOR, STUDENT)
export const getTests = createAsyncThunk(
   'testSlice/get-all-tests',
   async ({ lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getTestsRequest(lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get test by id ROLE(INSTRUCTOR, STUDENT)
export const getTestById = createAsyncThunk(
   'testSlice/getTestById',
   async ({ testId }, { rejectWithValue }) => {
      try {
         const { data } = await getTestByIdRequest(testId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get test as student ROLE(STUDENT)
export const getTestAsStudent = createAsyncThunk(
   'testSlice/get-test-as-student',
   async ({ testId }, { rejectWithValue }) => {
      try {
         const { data } = await getTestAsStudentRequest(testId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// pass test ROLE(STUDENT)
export const passStudentTest = createAsyncThunk(
   'testSlice/pass-student-test',
   async ({ testData, navigateToTests }, { rejectWithValue }) => {
      try {
         const { data } = await passTestRequest(testData)

         // notify('Тест пройден! Ожидайте результатов!')
         notify(t('test_form.test_passed'))
         navigateToTests()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get test answers ROLE(INSTRUCTOR)
export const getTestAnswers = createAsyncThunk(
   'testSlice/get-test-answers',
   async ({ testId }, { rejectWithValue }) => {
      try {
         const { data } = await getTestAnswersRequest(testId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get test preview ROLE(INSTRUCTOR)
export const getTestPreview = createAsyncThunk(
   'testSlice/get-test-preview',
   async ({ testId }, { rejectWithValue }) => {
      try {
         const { data } = await getTestByIdRequest(testId)
         return { ...data, time: getTestTime(data.timeToTakeTheTest) }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create test ROLE(INSTRUCTOR)
export const createTest = createAsyncThunk(
   'testSlice/create-test',
   async ({ lessonId, test, onConfirm }, { rejectWithValue }) => {
      try {
         const { data } = await createTestRequest(lessonId, test)
         notify(data.message)
         return onConfirm()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update test ROLE(INSTRUCTOR)
export const updateTest = createAsyncThunk(
   'testSlice/update-test',
   async ({ testId, test, onConfirm }, { rejectWithValue }) => {
      try {
         const { data } = await updateTestRequest(testId, test)
         notify(data.message)
         return onConfirm()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete test ROLE(INSTRUCTOR)
export const deleteTest = createAsyncThunk(
   'testSlice/delete-test',
   async ({ testId, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await deleteTestRequest(testId)
         notify(data.message)
         onClose()
         return testId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

//  update test visibility status ROLE(INSTRUCTOR)
export const changeTestVisibility = createAsyncThunk(
   'testSlice/block-test-answers',
   async ({ testId, onClose, values }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await blockTestAnswersRequest(testId, values)
         notify(data.message)
         dispatch(getTestAnswers({ notify, testId }))
         return onClose && onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get student test result ROLE(STUDENT)
export const getStudentTestResults = createAsyncThunk(
   'testSlice/get-student-answers',
   async ({ testId }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentAnswerByStudentRequest(testId)
         return convertStudentTestResults(data)
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get student test result ROLE(INSTRUCTOR)
export const getStudentTestResultsAsInstructor = createAsyncThunk(
   'testSlice/get-student-answers-as-instructor',
   async ({ testId, studentId }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentAnswerRequest(testId, studentId)
         return convertStudentTestResults(data)
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
