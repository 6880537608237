import axios from 'axios'
import { BASE_URL, localStorageKeyNames } from '../utils/constants/general'
import { localStorageHelper } from '../utils/helpers/localStorageHelper'

const { LOGIN_USER_INFORMATION, LANGUAGE } = localStorageKeyNames
const { removeLocalStorage, getFromLocalStorage, getLanguage } =
   localStorageHelper

const authData = getFromLocalStorage(LOGIN_USER_INFORMATION)
const language = getLanguage(LANGUAGE)

const headers = {
   'Content-Type': 'application/json',
   'Content-Language': language,
}

if (authData?.token) {
   headers.Authorization = `Bearer ${authData.token}`
}

const axiosInstance = axios.create({ baseURL: BASE_URL, headers })

const handleSuccess = (response) => {
   return Promise.resolve(response)
}

const handleError = (error) => {
   if (error.response?.status === 401) {
      removeLocalStorage(LOGIN_USER_INFORMATION)
      window.location.reload()
      return null
   }
   return Promise.reject(error)
}

axiosInstance.interceptors.response.use(handleSuccess, handleError)

export default axiosInstance
