import { getHourAndMinute } from './dateHelper'

export const convertEvent = (event) => {
   const startTime = getHourAndMinute(new Date(event.start))
   const endTime = getHourAndMinute(new Date(event.end))

   return { ...event, startTime, endTime, startDate: event.start }
}

export const clearUTCHours = (date) => {
   date.setUTCHours(0, 0, 0, 0)

   return date
}
