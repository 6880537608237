import axiosInstance from '../config/axiosInstance'

// lesson requests
export const getLessonsRequest = (courseId, params) => {
   return axiosInstance.get(`/courses/${courseId}/lessons`, { params })
}

export const getSingleLessonRequest = (courseId, lessonId) => {
   return axiosInstance.get(`/courses/${courseId}/lessons/${lessonId}`)
}

export const createLessonRequest = (courseId, lesson) => {
   return axiosInstance.post(`/courses/${courseId}/lessons`, lesson)
}

export const editLessonRequest = (courseId, lessonId, lesson) => {
   return axiosInstance.put(`/courses/${courseId}/lessons/${lessonId}`, lesson)
}

export const deleteLessonRequest = (courseId, lessonId) => {
   return axiosInstance.delete(`/courses/${courseId}/lessons/${lessonId}`)
}

export const dragLessonRequest = (courseId, lessonId, orderNum) => {
   return axiosInstance.put(
      `/courses/${courseId}/lessons/${lessonId}/drag/${orderNum}`
   )
}
