import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCourseStudentsRequest } from '../../api/courseService'
import {
   addGroupToCourseRequest,
   deleteGroupFromCourseRequest,
   getTeacherCoursesRequest,
} from '../../api/teacherCoursesService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'
import { getCourseById } from '../courses/courseActions'

// get students of teacher's course ROLE(INSTRUCTOR)
export const getTeacherStudents = createAsyncThunk(
   'teacherCourseSlice/getTeacherStudents',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getCourseStudentsRequest(courseId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get courses of teacher ROLE(INSTRUCTOR)
export const getTeacherCourses = createAsyncThunk(
   'teacherCourseSlice/getTeacherCourses',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getTeacherCoursesRequest(params)
         return { courses: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete group from course ROLE(INSTRUCTOR)
export const deleteGroupFromCourse = createAsyncThunk(
   'teacherCourseSlice/deleteGroupsFromCourse',
   async (
      { onClose, groupId, courseId, type },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const { data } = await deleteGroupFromCourseRequest(courseId, groupId)
         notify(data.message)
         if (type === 'INNER') {
            dispatch(getTeacherStudents({ courseId, notify }))
            dispatch(getCourseById({ courseId, notify }))
         } else {
            const { params } = getState().teacherCourses
            dispatch(getTeacherCourses({ params }))
         }
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// add group to course ROLE(INSTRUCTOR)
export const addGroupToCourse = createAsyncThunk(
   'teacherCourseSlice/add-group-to-course',
   async (
      { onClose, groupId, courseId, type },
      { rejectWithValue, dispatch }
   ) => {
      try {
         const { data } = await addGroupToCourseRequest(courseId, groupId)
         notify(data.message)
         if (type === 'INNER') {
            dispatch(getTeacherStudents({ courseId, notify }))
            dispatch(getCourseById({ courseId, notify }))
         } else {
            dispatch(getTeacherCourses())
         }
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
