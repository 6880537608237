import axiosInstance from '../config/axiosInstance'

// INSTRUCTOR COURSES
export const getTeacherCoursesRequest = (params) => {
   return axiosInstance.get('courses', { params })
}

export const deleteGroupFromCourseRequest = (courseId, groupId) => {
   return axiosInstance.delete(`courses/${courseId}/groups/${groupId}`)
}

export const addGroupToCourseRequest = (courseId, groupId) => {
   return axiosInstance.post(`courses/${courseId}/groups/${groupId}`)
}
