import axiosInstance from '../config/axiosInstance'

export const getPresentationsRequest = (lesId) => {
   return axiosInstance.get(`lessons/${lesId}/presentations`)
}

export const addLessonPresentationRequest = (lesId, presentation) => {
   return axiosInstance.post(`lessons/${lesId}/presentations`, presentation)
}

export const getPresentationByIdRequest = (lesId, presId) => {
   return axiosInstance.get(`lessons/${lesId}/presentations/${presId}`)
}

export const editPresentationRequest = (lesId, presId, pres) => {
   return axiosInstance.put(`lessons/${lesId}/presentations/${presId}`, pres)
}

export const deletePresentationRequest = (lesId, presId) => {
   return axiosInstance.delete(`lessons/${lesId}/presentations/${presId}`)
}
