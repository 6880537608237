import axiosInstance from '../config/axiosInstance'

// ADMIN
export const getTeachersRequest = (params) => {
   return axiosInstance.get('instructors', { params })
}

export const deleteTeacherRequest = (instructorId) => {
   return axiosInstance.delete(`instructors/${instructorId}`)
}

export const addTeacherRequest = (teacher) => {
   return axiosInstance.post('instructors', teacher)
}

export const editTeacherRequest = (teacher, teacherId) => {
   return axiosInstance.put(`instructors/${teacherId}`, teacher)
}

export const getTeacherByIdRequest = (teacherId) => {
   return axiosInstance.get(`instructors/${teacherId}`)
}
