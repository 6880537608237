import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store/index'
import './config/i18nextConfig'
import SnackbarUtilsConfigurator from './components/UI/Notifications/snackActions'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <Provider store={store}>
            <SnackbarProvider
               maxSnack={3}
               autoHideDuration={3000}
               preventDuplicate
            >
               <App />
               <SnackbarUtilsConfigurator />
            </SnackbarProvider>
         </Provider>
      </BrowserRouter>
   </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
