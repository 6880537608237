import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiAlert from '@mui/material/Alert'
import { ReactComponent as NotificationErrorIcon } from '../../../assets/icons/errorIcon.svg'
import { ReactComponent as NotificationSuccessIcon } from '../../../assets/icons/successIcon.svg'
import media from '../../../utils/constants/media'

const StyledAlert = styled(MuiAlert)`
   &.MuiAlert-root {
      margin-left: auto;
      max-width: calc(100vw - 70vw);

      background: ${(props) =>
         props.variant === 'error' ? '#EA1313' : '#36AC0C'};
      border-radius: 10px;

      ${media.response`
      max-width: calc(100vw - 55vw);
      `}
   }
   & .MuiAlert-message {
      display: grid;
      width: 100%;
      grid-template-columns: auto auto;
      gap: 20px;

      justify-content: space-between;
      align-items: center;
      padding: 0;

      min-height: 50px;
   }
`

const NotificationMessage = styled.p`
   color: #ffffff;
   overflow: hidden;
   text-overflow: ellipsis;

   display: -webkit-box;
   -webkit-line-clamp: 3;
   line-clamp: 3;
   -webkit-box-orient: vertical;

   margin: 0;

   font-size: 16px;
   letter-spacing: 0.02em;
`

const ICONS = {
   success: <NotificationSuccessIcon />,
   error: <NotificationErrorIcon />,
}

const Notification = forwardRef(({ message, variant, id, ...props }, ref) => {
   return (
      <StyledAlert variant={variant} icon={false} {...props} ref={ref}>
         <NotificationMessage>{message}</NotificationMessage>
         {ICONS[variant]}
      </StyledAlert>
   )
})

Notification.propTypes = {
   message: PropTypes.string.isRequired,
   id: PropTypes.number.isRequired,
   variant: PropTypes.string,
}

Notification.defaultProps = {
   variant: 'success',
}

export default Notification
