import { useSnackbar as useDefaultSnackbar } from 'notistack'
import Notification from './Notification'

const useEnqueueSnackbar = () => {
   const { enqueueSnackbar } = useDefaultSnackbar()

   const pushSnackbar = (message, variant) => {
      enqueueSnackbar(message, {
         anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
         },
         content: (id) => {
            return <Notification id={id} message={message} variant={variant} />
         },
      })
   }

   return pushSnackbar
}
export default useEnqueueSnackbar
