import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   forgotPasswordRequest,
   loginRequest,
   resetPasswordRequest,
} from '../../api/authService'
import axiosInstance from '../../config/axiosInstance'
import {
   localStorageKeyNames,
   MAIN_ROUTES,
} from '../../utils/constants/general'
import { getRequestError } from '../../utils/helpers/requestHelper'
import { localStorageHelper } from '../../utils/helpers/localStorageHelper'
import { notify } from '../../components/UI/Notifications/snackActions'
import formDataInstance from '../../config/axiosInstanceFormData'
import i18n from '../../config/i18nextConfig'

const { LOGIN_USER_INFORMATION } = localStorageKeyNames

export const login = createAsyncThunk(
   'auth/login-request',
   async ({ values, navigate }, { rejectWithValue }) => {
      try {
         const credentials = {
            email: values.email.toLowerCase(),
            password: values.password,
         }
         const { data } = await loginRequest(credentials)
         const payloadData = {
            token: data.token,
            role: data.authority,
            isAuthorized: !!data.token && !!data.authority,
         }

         localStorageHelper.setLocalStorage(LOGIN_USER_INFORMATION, payloadData)
         axiosInstance.defaults.headers.Authorization = `Bearer ${data.token}`
         formDataInstance.defaults.headers.Authorization = `Bearer ${data.token}`
         axiosInstance.defaults.headers['Content-Language'] = values.language
         i18n.changeLanguage(values.language)
         navigate(MAIN_ROUTES[payloadData.role].path, { replace: true })

         return payloadData
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const forgotPassword = createAsyncThunk(
   'auth/forgot-password',
   async ({ values, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await forgotPasswordRequest(values)

         notify(data.message)
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const resetPassword = createAsyncThunk(
   'auth/reset-password',
   async ({ values, navigate }, { rejectWithValue }) => {
      try {
         const { data } = await resetPasswordRequest(values)
         const payloadData = {
            token: data.token,
            role: data.authority,
            isAuthorized: !!data.token && !!data.authority,
         }
         localStorageHelper.setLocalStorage(LOGIN_USER_INFORMATION, payloadData)
         axiosInstance.defaults.headers.Authorization = `Bearer ${data.token}`
         formDataInstance.defaults.headers.Authorization = `Bearer ${data.token}`

         navigate(MAIN_ROUTES[payloadData.role].path, { replace: true })
         return payloadData
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const logout = createAsyncThunk(
   'auth/logout',
   (_, { rejectWithValue }) => {
      try {
         localStorageHelper.clearLocalStorage()
         delete axiosInstance.defaults.headers.Authorization
         window.location = '/login'
         return 'logged out'
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
