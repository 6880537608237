import { createAsyncThunk } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import {
   assignTeacherRequest,
   blockStudentRequest,
   createCourseRequest,
   deleteCourseRequest,
   deleteTeacherFromCourseRequest,
   getCourseByIdRequest,
   getCoursesRequest,
   getCourseStudentsRequest,
   getCourseTeachersRequest,
   unblockStudentRequest,
   updateCourseRequest,
} from '../../api/courseService'
import {
   presignedFileUpload,
   uploadFileRequest,
} from '../../api/fileUploadService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get courses ROLE(ADMIN, INSTRUCTOR)
export const getCourses = createAsyncThunk(
   'courseSlice/getCourses',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getCoursesRequest(params)
         return { courses: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get courses ROLE(STUDENT)
export const getStudentsCourses = createAsyncThunk(
   'courseSlice/getStudentsCourses',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getCoursesRequest(params)
         return { courses: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create course ROLE(ADMIN)
export const createCourse = createAsyncThunk(
   'courseSlice/createCourses',
   async ({ course, onClose }, { rejectWithValue, dispatch, getState }) => {
      try {
         const dataWithFile = {
            imageId: 0,
            courseName: course.courseName,
            dateOfFinish: format(course.dateOfFinish, 'yyyy-MM-dd'),
            description: course.description,
         }
         if (course.binaryImage) {
            const uploadedFile = await presignedFileUpload(course.binaryImage)
            dataWithFile.imageId = uploadedFile.data.fileId
         }
         const { data } = await createCourseRequest(dataWithFile)
         notify(data.message)

         const { params } = getState().courses
         dispatch(getCourses({ params }))

         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete course ROLE(ADMIN)
export const deleteCourse = createAsyncThunk(
   'courseSlice/deleteCourses',
   async ({ courseId, onClose }, { rejectWithValue, getState, dispatch }) => {
      try {
         const { data } = await deleteCourseRequest(courseId)
         notify(data.message)

         const { params } = getState().courses
         dispatch(getCourses({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get course by id ROLE(ALL)
export const getCourseById = createAsyncThunk(
   'courseSlice/getCourseById',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getCourseByIdRequest(courseId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update course ROLE(ADMIN)
export const updateCourse = createAsyncThunk(
   'courseSlice/updateCourses',
   async (
      { course, courseId, onClose },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const dataWithFile = {
            imageId: 0,
            courseName: course.courseName,
            dateOfFinish: course.dateOfFinish,
            description: course.description,
         }
         if (course.binaryImage) {
            const uploadedFile = await uploadFileRequest(course.binaryImage)
            dataWithFile.imageId = uploadedFile.data.fileId
         }
         const { data } = await updateCourseRequest(courseId, dataWithFile)
         notify(data.message)

         const { params } = getState().courses
         dispatch(getCourses({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const assignTeacherToCourse = createAsyncThunk(
   'courseSlice/assign-teacher-to-course',
   async (
      { instructors, courseId, onClose },
      { rejectWithValue, dispatch }
   ) => {
      try {
         const { data } = await assignTeacherRequest(instructors, courseId)
         notify(data.message)
         dispatch(getCourseTeachers({ courseId, notify }))
         onClose()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get students of course @ADMIN, INSTRUCTOR
export const getCourseStudents = createAsyncThunk(
   'courseSlice/getCourseStudents',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getCourseStudentsRequest(courseId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get teachers of course @ADMIN
export const getCourseTeachers = createAsyncThunk(
   'courseSlice/getCourseTeachers',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getCourseTeachersRequest(courseId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const deleteTeacherFromCourse = createAsyncThunk(
   'courseSlice/delete-course-teachers',
   async ({ courseId, teacherId, onClose }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await deleteTeacherFromCourseRequest(
            courseId,
            teacherId
         )
         onClose()
         notify(data.message)
         dispatch(getCourseTeachers({ courseId, notify }))
         return teacherId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const blockStudent = createAsyncThunk(
   'courseSlice/block-students',
   async ({ studentId, courseId }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await blockStudentRequest(courseId, studentId)
         dispatch(getCourseStudents({ courseId, notify }))

         notify(data.message)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const unblockStudent = createAsyncThunk(
   'courseSlice/unblock-students',
   async ({ studentId, courseId }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await unblockStudentRequest(courseId, studentId)
         dispatch(getCourseStudents({ courseId, notify }))

         notify(data.message)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
