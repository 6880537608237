import React, { lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router'
import PrivateRoute from './PrivateRoute'
import { ROLES } from '../utils/constants/general'

// use when enable authorization
const AdminSignIn = lazy(() => import('../containers/Auth/AdminSignIn'))
const AdminSignUp = lazy(() => import('../containers/Auth/AdminSignUp'))
const LoginPage = lazy(() => import('../containers/Auth/LoginPage'))
const LoginForm = lazy(() => import('../containers/Auth/LoginForm'))
const AdminLayout = lazy(() => import('../layouts/AdminLayout'))
const InstructorLayout = lazy(() => import('../layouts/TeacherLayout'))
const StudentLayout = lazy(() => import('../layouts/StudentLayout'))
const ResetPasswordForm = lazy(() =>
   import('../containers/Auth/ResetPasswordForm')
)

const MainRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Navigate to="/login" />} />
         <Route
            path="/login/*"
            element={
               <LoginPage>
                  <LoginForm />
               </LoginPage>
            }
         />
         <Route path="/sign-up" element={<AdminSignUp />} />
         <Route path="/sign-in" element={<AdminSignIn />} />
         <Route
            path="/reset-password/:token"
            element={
               <LoginPage>
                  <ResetPasswordForm />
               </LoginPage>
            }
         />
         <Route
            path="/admin/*"
            element={
               <PrivateRoute
                  userRole={ROLES.ADMIN}
                  Component={<AdminLayout />}
               />
            }
         />
         <Route
            path="/instructor/*"
            element={
               <PrivateRoute
                  userRole={ROLES.INSTRUCTOR}
                  Component={<InstructorLayout />}
               />
            }
         />
         <Route
            path="/student/*"
            element={
               <PrivateRoute
                  userRole={ROLES.STUDENT}
                  Component={<StudentLayout />}
               />
            }
         />
      </Routes>
   )
}

export default MainRoutes
