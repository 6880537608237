import { createSlice } from '@reduxjs/toolkit'
import {
   getTaskAnswers,
   getTaskById,
   getTasks,
   deleteTask,
   getStudentAnswerAsInstructor,
   getStudentTask,
} from './taskActions'

const initialState = {
   tasks: [],
   instructorTask: { taskElements: [], deadline: '', taskId: 0, taskName: '' },
   taskAnswers: {
      waiting: [],
      accepted: [],
      notAccepted: [],
      late: [],
      notSubmitted: [],
   },
   studentTask: {},
   isLoading: false,
}

const tasksSlice = createSlice({
   name: 'tasksSlice',
   initialState,
   reducers: {
      clearTasks(state) {
         state.tasks = []
      },
      clearTask(state) {
         state.instructorTask = {
            deadline: '',
            taskId: 0,
            taskName: '',
            description: '',
         }
         state.studentTask = {}
      },
      clearStudentTask(state) {
         state.studentTask = {}
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getTasks.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTasks.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTasks.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.tasks = payload
         })

         .addCase(getTaskAnswers.fulfilled, (state, { payload }) => {
            state.taskAnswers = payload
         })
         .addCase(
            getStudentAnswerAsInstructor.fulfilled,
            (state, { payload }) => {
               state.studentTask = payload
            }
         )
         .addCase(getStudentTask.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getStudentTask.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getStudentTask.fulfilled, (state, { payload }) => {
            state.studentTask = payload
            state.isLoading = false
         })
         .addCase(deleteTask.fulfilled, (state, { payload: taskId }) => {
            state.tasks = state.tasks.filter((task) => +task.taskId !== +taskId)
         })
         .addCase(getTaskById.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTaskById.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTaskById.fulfilled, (state, { payload }) => {
            state.instructorTask = payload
            state.isLoading = false
         })
   },
})

export const { clearTasks, clearStudentTask, clearTask } = tasksSlice.actions

export default tasksSlice
