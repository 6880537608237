import { createSlice } from '@reduxjs/toolkit'
import { getTeachers } from './teacherActions'

const initialState = {
   teachers: [],
   isLoading: false,
   count: 0,
   params: { page: 1, size: 0 },
}

const teachersSlice = createSlice({
   name: 'teachersSlice',
   initialState,
   reducers: {
      clearTeachers(state) {
         state.teachers = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getTeachers.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTeachers.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTeachers.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.teachers = payload.teachers
            state.count = payload.count
            state.params = payload.params
         })
   },
})

export const { clearTeachers } = teachersSlice.actions

export default teachersSlice
