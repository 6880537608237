import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({ Component, userRole }) => {
   const { role } = useSelector((store) => store.auth)
   const isUserHasRole = role && userRole.includes(role)
   if (!isUserHasRole) return <Navigate to="/login" replace />
   return Component
}

export default PrivateRoute

PrivateRoute.propTypes = {
   Component: PropTypes.node.isRequired,
   userRole: PropTypes.string.isRequired,
}
