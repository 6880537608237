import { createSlice } from '@reduxjs/toolkit'
import { convertEvent } from '../../utils/helpers/calendarHelper'
import {
   deleteEvent,
   getEventById,
   getEvents,
   getEventsByDate,
   publishEvent,
} from './calendarActions'

const initialState = {
   events: [],
   event: null,
   isLoading: false,

   dayEvents: [],

   search: {},
}

const calendarSlice = createSlice({
   name: 'calendarSlice',
   initialState,
   reducers: {
      clearEvents(state) {
         state.events = []
      },
      clearEvent(state) {
         state.event = null
      },
      clearDayEvents(state) {
         state.dayEvents = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getEvents.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getEvents.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getEvents.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.events = payload.events
            state.search = payload.search
         })

         .addCase(getEventById.fulfilled, (state, { payload: event }) => {
            state.event = convertEvent(event)
         })

         .addCase(deleteEvent.fulfilled, (state, { payload }) => {
            state.events = state.events.filter((e) => +e.id !== +payload)
            state.dayEvents = state.dayEvents.filter((e) => +e.id !== +payload)
         })

         .addCase(getEventsByDate.fulfilled, (state, { payload }) => {
            state.dayEvents = payload
         })

         .addCase(publishEvent.fulfilled, (state, { payload }) => {
            state.dayEvents = state.dayEvents.map((day) => {
               if (day.id === payload) return { ...day, status: 'ACTIVE' }
               return day
            })
         })
   },
})

export const { clearEvents, clearEvent, clearDayEvents } = calendarSlice.actions

export default calendarSlice
