import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   addLessonVideoRequest,
   getVideoByIdRequest,
   getVideosRequest,
   editVideoRequest,
   deleteVideoRequest,
} from '../../api/videoService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get all videos ROLE(INSTRUCTOR, STUDENT)
export const getVideos = createAsyncThunk(
   'videoSlice/getVideos',
   async ({ lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getVideosRequest(lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// add video ROLE(INSTRUCTOR)
export const addVideo = createAsyncThunk(
   'videoSlice/addVideo',
   async ({ lessonId, video, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await addLessonVideoRequest(lessonId, video)
         notify(data.message)
         onClose()
         return data.videos
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get video by id ROLE(INSTRUCTOR)
export const getVideoById = createAsyncThunk(
   'videoSlice/getVideoById',
   async ({ videoId, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getVideoByIdRequest(lessonId, videoId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update video ROLE(INSTRUCTOR)
export const editVideo = createAsyncThunk(
   'videoSlice/editVideo',
   async ({ video, onClose, videoId, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await editVideoRequest(lessonId, videoId, video)
         notify(data.message)
         onClose()
         return data.videos
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete video ROLE(INSTRUCTOR)
export const deleteVideo = createAsyncThunk(
   'videoSlice/deleteVideo',
   async ({ videoId, onClose, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await deleteVideoRequest(lessonId, videoId)
         notify(data.message)
         onClose()
         return videoId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
