import axiosInstance from '../config/axiosInstance'

export const getNotificationsRequest = () => {
   return axiosInstance.get(`/notifications`)
}

export const deleteNotificationRequest = (notId) => {
   return axiosInstance.delete(`notifications/delete/${notId}`)
}

export const viewNotificationRequest = (notId) => {
   return axiosInstance.put(`/notifications/view/${notId}`)
}

export const updateNotSettingsRequest = (settings) => {
   return axiosInstance.put(`/notifications/settings`, settings)
}
