import { createSlice } from '@reduxjs/toolkit'
import { localStorageHelper } from '../../utils/helpers/localStorageHelper'
import { localStorageKeyNames } from '../../utils/constants/general'
import { login, logout, resetPassword } from './authActions'

const { LOGIN_USER_INFORMATION } = localStorageKeyNames

const initState = {
   token: '',
   role: '',
   isAuthorized: false,
   isLoading: false,
}

const authSlice = createSlice({
   name: 'auth',
   initialState:
      localStorageHelper.getFromLocalStorage(LOGIN_USER_INFORMATION) ||
      initState,
   reducers: {
      authLogin(state, { payload }) {
         state.isLoading = false
         const { role, token, isAuthorized } = payload
         state.token = token
         state.role = role
         state.isAuthorized = isAuthorized
      },
   },
   extraReducers(builder) {
      builder
         .addCase(login.pending, (state) => {
            state.isLoading = true
         })
         .addCase(login.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(login.fulfilled, (state, { payload }) => {
            const { role, token, isAuthorized } = payload
            state.isLoading = false
            state.token = token
            state.role = role
            state.isAuthorized = isAuthorized
         })

         .addCase(logout.fulfilled, (state) => {
            state.token = ''
            state.role = ''
            state.isAuthorized = false
         })

         .addCase(resetPassword.pending, (state) => {
            state.isLoading = true
         })
         .addCase(resetPassword.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(resetPassword.fulfilled, (state, { payload }) => {
            const { role, token, isAuthorized } = payload
            state.isLoading = false
            state.token = token
            state.role = role
            state.isAuthorized = isAuthorized
         })
   },
})

export const authActions = authSlice.actions

export default authSlice
