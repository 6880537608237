import { isRejectedWithValue } from '@reduxjs/toolkit'
import { handleRequestError } from '../../utils/helpers/requestHelper'
import { logout } from '../auth/authActions'

// const TOKEN_ERROR_MESSAGE =
//    'Smth wrong with your token. you will be redirected to login page'

export const requestErrorMiddleware = (api) => (next) => (action) => {
   if (isRejectedWithValue(action)) {
      if (typeof action?.payload?.status === 'number') {
         switch (action?.payload?.status) {
            case 403: {
               // setTimeout(() => api.dispatch(logout()), 2000)
               handleRequestError(action.payload?.message)
               break
            }
            case 401: {
               if (action.payload?.message === 'Вы вошли с другого аккаунта') {
                  setTimeout(() => api.dispatch(logout()), 1000)
               }
               handleRequestError(action.payload?.message)
               break
            }
            default: {
               handleRequestError(action.payload?.message)
               break
            }
         }
      }
   }

   return next(action)
}
