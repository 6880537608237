import { createSlice } from '@reduxjs/toolkit'
import { getLinks, deleteLink, createLink, updateLink } from './linkActions'

const initialState = {
   links: [],
   isLoading: false,
}

const linkSlice = createSlice({
   name: 'linkSlice',
   initialState,
   reducers: {
      clearLinks(state) {
         state.links = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getLinks.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getLinks.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getLinks.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.links = payload
         })

         .addCase(createLink.fulfilled, (state, { payload }) => {
            state.links = payload
         })
         .addCase(updateLink.fulfilled, (state, { payload }) => {
            state.links = payload
         })
         .addCase(deleteLink.fulfilled, (state, { payload: linkId }) => {
            state.links = state.links.filter((link) => link.id !== +linkId)
         })
   },
})

export const { clearLinks } = linkSlice.actions

export default linkSlice
