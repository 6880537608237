import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   createExamRequest,
   deleteExamRequest,
   getAllStudentRatingRequest,
   getCourseRatingRequest,
   getStudentRatingRequest,
   giveExamMarkRequest,
   setRatingDistributionRequest,
} from '../../api/ratingService'
import {
   getRequestError,
   handleRequestError,
} from '../../utils/helpers/requestHelper'
import { syncronizeRatingData } from '../../utils/helpers/ratingHelper'
import { notify } from '../../components/UI/Notifications/snackActions'

// get course rating ROLE(INSTRUCTOR)
export const getCourseRating = createAsyncThunk(
   'ratingSlice/get-course-rating',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getCourseRatingRequest(courseId)
         return syncronizeRatingData({ ...data })
      } catch (error) {
         if (error?.response?.status !== 400) {
            return handleRequestError(getRequestError(error))
         }
         return rejectWithValue(getRequestError(error))
      }
   }
)

// set rating distribution ROLE(INSTRUCTOR)
export const setRatingDistribution = createAsyncThunk(
   'ratingSlice/set-rating-distribution',
   async ({ courseId, onClose, values }, { rejectWithValue }) => {
      try {
         const { data } = await setRatingDistributionRequest(courseId, values)
         notify(data.message)
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create rating exam ROLE(INSTRUCTOR)
export const createExam = createAsyncThunk(
   'ratingSlice/create-rating-exam',
   async ({ courseId, onClose, exam }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await createExamRequest(courseId, exam)
         notify(data.message)
         onClose()

         dispatch(getCourseRating({ courseId, notify }))
         return 'Good!'
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete rating exam ROLE(INSTRUCTOR)
export const deleteExam = createAsyncThunk(
   'ratingSlice/delete-rating-exam',
   async ({ courseId, examId, onClose }, { rejectWithValue, dispatch }) => {
      try {
         const { data } = await deleteExamRequest(examId)
         notify(data.message)
         onClose()
         dispatch(getCourseRating({ courseId, notify }))
         return 'Good!'
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// give exam mark ROLE(INSTRUCTOR)
export const giveExamMark = createAsyncThunk(
   'ratingSlice/delete-rating-exam',
   async ({ mark, examId, studentId }, { rejectWithValue }) => {
      try {
         const { data } = await giveExamMarkRequest(examId, studentId, mark)
         notify(data.message)
         return 'Good!!!'
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get rating of all students of course ROLE(STUDENT)
export const getAllStudentsRating = createAsyncThunk(
   'ratingSlice/get-all-students-rating',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getAllStudentRatingRequest(courseId)
         return data || []
      } catch (error) {
         if (error?.response?.status !== 400) {
            return handleRequestError(getRequestError(error))
         }
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get rating of student ROLE(STUDENT)
export const getStudentRating = createAsyncThunk(
   'ratingSlice/get-student-rating',
   async ({ courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentRatingRequest(courseId)

         console.log(syncronizeRatingData({ ...data }))
         return syncronizeRatingData({ ...data })
      } catch (error) {
         if (error?.response?.status !== 400) {
            return handleRequestError(getRequestError(error))
         }
         return rejectWithValue(getRequestError(error))
      }
   }
)
