import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   deleteNotificationRequest,
   getNotificationsRequest,
   updateNotSettingsRequest,
   viewNotificationRequest,
} from '../../api/notificationsService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

export const getNotifications = createAsyncThunk(
   'notificationSlice/get-notification',
   async (_, { rejectWithValue }) => {
      try {
         const { data } = await getNotificationsRequest()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const deleteNotification = createAsyncThunk(
   'notificationSlice/delete-notification',
   async ({ notId, type }, { rejectWithValue }) => {
      try {
         const { data } = await deleteNotificationRequest(notId)
         notify(data.message)
         return { type, notId }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
export const viewNotification = createAsyncThunk(
   'notificationSlice/view-notification',
   async ({ notId }, { rejectWithValue }) => {
      try {
         const { data } = await viewNotificationRequest(notId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const updateNotSettings = createAsyncThunk(
   'notificationSlice/update-notification-settings',
   async ({ settings }, { rejectWithValue }) => {
      try {
         const { data } = await updateNotSettingsRequest(settings)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
