import { createSlice } from '@reduxjs/toolkit'
import { getTrashItems } from './trashActions'

const initialState = {
   items: [],
   count: 0,
   isLoading: false,
   params: { page: 1, size: 0 },
}

const deletedItemSlice = createSlice({
   name: 'trashItems',
   initialState,
   reducers: {
      clearItems(state) {
         state.items = []
      },
   },
   extraReducers(builder) {
      builder.addCase(getTrashItems.pending, (state) => {
         state.isLoading = true
      })
      builder.addCase(getTrashItems.rejected, (state) => {
         state.isLoading = false
      })
      builder.addCase(getTrashItems.fulfilled, (state, { payload }) => {
         state.isLoading = false
         state.items = payload.items
         state.count = payload.count
         state.params = payload.params
      })
   },
})

export const { clearItems } = deletedItemSlice.actions

export default deletedItemSlice
