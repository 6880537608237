import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   addStudentRequest,
   getStudentsRequest,
   deleteStudentRequest,
   getStudentByIdRequest,
   editStudentByIdRequest,
   importExcelRequest,
   blockStudentRequest,
   unblockStudentRequest,
} from '../../api/studentsService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

export const getStudents = createAsyncThunk(
   'studentSlice/getStudents',
   async ({ params }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentsRequest(params)
         return { students: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const addStudent = createAsyncThunk(
   'studentSlice/addStudent',
   async (
      { studentData, onClose },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { data } = await addStudentRequest(studentData)
         notify(data.message)

         const { params } = getState().students
         dispatch(getStudents({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const deleteStudent = createAsyncThunk(
   'studentSlice/deleteStudent',
   async ({ studentId, onClose }, { rejectWithValue, dispatch, getState }) => {
      try {
         const { data } = await deleteStudentRequest(studentId)
         notify(data.message)

         const { params } = getState().students
         dispatch(getStudents({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const getStudentById = createAsyncThunk(
   'studentSlice/getStudent',
   async ({ studentId }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentByIdRequest(studentId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const editStudent = createAsyncThunk(
   'studentSlice/editStudent',
   async (
      { student, studentId, onClose },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const { data } = await editStudentByIdRequest(studentId, student)
         notify(data.message)

         const { params } = getState().students
         dispatch(getStudents({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const blockStudent = createAsyncThunk(
   'studentSlice/blockStudent',
   async (
      { studentId, onClose, isBlocked },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         let response
         if (isBlocked) {
            response = await (await unblockStudentRequest(studentId)).data
         } else {
            response = await (await blockStudentRequest(studentId)).data
         }
         notify(response?.message)

         const { params } = getState().students
         dispatch(getStudents({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const importExcelStudents = createAsyncThunk(
   'studentSlice/importExcelStudents',
   async (
      { studentsExel, onClose, groupId },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const { data } = await importExcelRequest(groupId, studentsExel)
         notify(data.message)
         onClose()

         const { params } = getState().students
         dispatch(getStudents({ params }))
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
