import { createSlice } from '@reduxjs/toolkit'
import { getAnnouncements, getAnnouncementById } from './announcementsActions'

const initialState = {
   announcements: [],
   announcement: null,
   isLoading: false,
   announcementGroups: [],
   count: 0,
   params: { page: 1, size: 0 },
}
const announcementsSlice = createSlice({
   name: 'announcementSlice',
   initialState,
   reducers: {
      clearAnnouncements(state) {
         state.announcements = []
      },
      clearAnnouncement(state) {
         state.announcement = null
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getAnnouncements.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getAnnouncements.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getAnnouncements.fulfilled, (state, { payload }) => {
            state.announcements = payload.announcements
            state.count = payload.count
            state.isLoading = false
            state.params = payload.params
         })

         .addCase(getAnnouncementById.fulfilled, (state, { payload }) => {
            state.announcement = payload
         })
   },
})

export const {
   clearAnnouncements,
   clearAnnouncement,
   clearAnnouncementGroups,
} = announcementsSlice.actions

export default announcementsSlice
