import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   deleteFileRequest,
   presignedFileUpload,
   uploadFileRequest,
} from '../../api/fileUploadService'
import {
   createLessonRequest,
   deleteLessonRequest,
   dragLessonRequest,
   editLessonRequest,
   getLessonsRequest,
   getSingleLessonRequest,
} from '../../api/lessonService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get lessons ROLE(INSTRUCTOR, STUDENT)
export const getLessons = createAsyncThunk(
   'lessonSlice/getLessons',
   async ({ courseId, params }, { rejectWithValue }) => {
      try {
         const { data } = await getLessonsRequest(courseId, params)
         return { lessons: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// upload file or image ROLE(INSTRUCTOR, STUDENT)
export const uploadFileOrImage = createAsyncThunk(
   'lessonSlice/upload-file-or-image',
   async ({ file, id }, { rejectWithValue }) => {
      try {
         if (!file) return null
         const formData = new FormData()
         formData.append('file', file)
         // const { data } = await uploadFileRequest(formData)
         const { data } = await presignedFileUpload(formData)
         return { ...data, id }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// export const presignedUploadFileOrImage = createAsyncThunk(
//    'lessonSLice/presigned-file-or-image',
//    async ({ file, id }, { rejectWithValue }) => {
//       try {
//         if (!file) return null
//         const formData = new FormData()
//         formData.append('file', file)
//       } catch (error) {
//          return rejectWithValue(getRequestError(error))
//       }
//    }
// )

// delete file or image ROLE(INSTRUCTOR, STUDENT)
export const deleteFileOrImage = createAsyncThunk(
   'lessonSlice/delete-file-or-image',
   async ({ link }, { rejectWithValue }) => {
      try {
         const { data } = await deleteFileRequest(link)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update lesson ROLE(INSTRUCTOR)
export const editLesson = createAsyncThunk(
   'lessonSlice/editLesson',
   async (
      { lessonId, onClose, courseId, lesson },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { params } = getState().lessons
         const { data } = await editLessonRequest(courseId, lessonId, lesson)
         dispatch(getLessons({ courseId, params }))
         notify(data.message)
         onClose()
         return data.allLessons
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create lesson ROLE(INSTRUCTOR)
export const createLesson = createAsyncThunk(
   'lessonSlice/create-teacher-lessons',
   async (
      { courseId, onClose, lesson },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { params } = getState().lessons
         const { data } = await createLessonRequest(courseId, lesson)
         dispatch(getLessons({ courseId, params }))
         notify(data.message)
         onClose()
         return data?.allLessons
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get lesson by id ROLE(INSTRUCTOR, STUDENT)
export const getLessonById = createAsyncThunk(
   'lessonSlice/get-teacher-lesson-by-id',
   async ({ lessonId, courseId }, { rejectWithValue }) => {
      try {
         const { data } = await getSingleLessonRequest(courseId, lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete lesson ROLE(INSTRUCTOR)
export const deleteLesson = createAsyncThunk(
   'courseSlice/delete-lesson',
   async (
      { lessonId, onClose, courseId },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const { params } = getState().lessons
         const { data } = await deleteLessonRequest(courseId, lessonId)
         dispatch(getLessons({ courseId, params }))
         notify(data.message)
         onClose()
         return +lessonId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

export const dragLesson = createAsyncThunk(
   'courseSlice/drag-lesson',
   async ({ lessonId, courseId, orderNum, source }, { rejectWithValue }) => {
      try {
         await dragLessonRequest(courseId, lessonId, orderNum + 1)

         return { lessonId, orderNum, source }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
