import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   presignedFileUpload,
   uploadFileRequest,
} from '../../api/fileUploadService'
import {
   createGroupRequest,
   deleteGroupById,
   getGroupsRequest,
   editGroupById,
   getGroupStudentsRequest,
   getGroupByIdRequest,
} from '../../api/groupService'
import { getRequestError } from '../../utils/helpers/requestHelper'
import { formatDate } from '../../utils/helpers/dateHelper'
import { notify } from '../../components/UI/Notifications/snackActions'

// get groups ROLE(ADMIN, INSTRUCTOR)

export const getGroups = createAsyncThunk(
   'groupSlice/getGroups',
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await getGroupsRequest(params)
         return { groups: data.objects, count: data.count, params }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create group ROLE(ADMIN)
export const createGroup = createAsyncThunk(
   'groupSlice/createGroup',
   async ({ groupData, onClose }, { rejectWithValue, dispatch, getState }) => {
      try {
         console.log(groupData.binaryImage.get('file'))
         const group = {
            groupName: groupData.name,
            description: groupData.description,
            dateOfFinish: formatDate(groupData.dateOfFinish, 'yyyy-MM-dd'),
            imageId: 0,
         }
         if (groupData.binaryImage) {
            URL.revokeObjectURL(groupData.imageLink)
            const uploadedFile = await presignedFileUpload(
               groupData.binaryImage
            )
            // const uploadedFile = await uploadFileRequest(groupData.binaryImage)
            group.imageId = await uploadedFile.data.fileId
         }
         const { data } = await createGroupRequest(group)
         const { params } = getState().groups
         dispatch(getGroups(params))
         notify(data.message)
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete group ROLE(ADMIN)
export const deleteGroup = createAsyncThunk(
   'groupSlice/deleteGroup',
   async ({ groupId, onClose }, { rejectWithValue, getState, dispatch }) => {
      try {
         const { data } = await deleteGroupById(groupId)
         notify(data.message)
         onClose()
         const { params } = getState().groups
         return dispatch(getGroups(params))
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update group ROLE(ADMIN)
export const editGroup = createAsyncThunk(
   'groupSlice/editGroup',
   async (
      { groupData, onClose, groupId },
      { rejectWithValue, dispatch, getState }
   ) => {
      try {
         const group = {
            groupName: groupData.name,
            description: groupData.description,
            dateOfFinish: groupData.dateOfFinish,
            imageId: 0,
         }
         if (groupData?.binaryImage) {
            // const uploadedFile = await uploadFileRequest(groupData.binaryImage)
            const uploadedFile = await presignedFileUpload(
               groupData.binaryImage
            )
            group.imageId = await uploadedFile.data.fileId
         }
         const { data } = await editGroupById(groupId, group)

         const { params } = getState().groups
         dispatch(getGroups(params))
         notify(data.message)
         return onClose()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get group by id ROLE(ADMIN)
export const getGroupById = createAsyncThunk(
   'groupSlice/getGroupById',
   async ({ groupId }, { rejectWithValue }) => {
      try {
         const { data } = await getGroupByIdRequest(groupId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get students of group ROLE(ADMIN)
export const getGroupStudents = createAsyncThunk(
   'groupSlice/getGroupStudents',
   async ({ groupId }, { rejectWithValue }) => {
      try {
         const { data } = await getGroupStudentsRequest(groupId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
