import axios from 'axios'
import axiosInstance from '../config/axiosInstance'
import formDataInstance from '../config/axiosInstanceFormData'

export const uploadFileRequest = (file) => {
   return formDataInstance.post('/static/upload', file)
}

export const getFile = async (key) => {
   return formDataInstance.get('static/generate/get', { params: { key } })
}

export const presignedFileUpload = async (
   file,
   onProgress,
   controller = new AbortController()
) => {
   const localFile = file?.get('file')
   const fileName = localFile?.name || ''
   const contentType = localFile?.type || ''
   const response = await axiosInstance.post(
      'static/generate/upload',
      {},
      {
         params: {
            fileName,
            contentType,
         },
      }
   )
   const {
      fileName: fileKeyName,
      fileId,
      url,
      signedHeaders,
   } = response.data || {}
   console.log('file.get("file")', file.get('file'))
   const fileBlob = new Blob([file.get('file')])
   const { data: generatedFileConfigs } = await axios.put(url, fileBlob, {
      headers: {
         // common: {
         //    Host: signedHeaders.host,
         // },
         'Content-Type': contentType,
         'x-amz-meta-original-filename':
            signedHeaders['x-amz-meta-original-filename'],
      },
      signal: controller.signal, // Pass the AbortSignal
      onUploadProgress: (progressEvent) => {
         if (onProgress) {
            const progress = {
               loaded: Math.round(progressEvent.loaded / 1024),
               total: Math.round((progressEvent.total || 0) / 1024),
            }
            onProgress(progress)
         }
      },
   })
   console.log('generatedFileConfigs', generatedFileConfigs)
   return response
}

export const deleteFileRequest = (link) => {
   return axiosInstance.delete(`static/delete`, { params: { fileLink: link } })
}
