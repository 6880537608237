import { notify } from '../../components/UI/Notifications/snackActions'
import { t } from '../../config/i18nextConfig'

export const getRequestError = (error) => {
   const errorObject = {}
   errorObject.message = error?.response?.data?.message || error?.message
   errorObject.status = error?.response?.status

   return errorObject
}

export const handleRequestError = (errorMessage) => {
   if (errorMessage) return notify(errorMessage, 'error')
   return notify(t('notification.error'), 'error')
}
