import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from '../assets/locales/en/translation.json'
import translationRU from '../assets/locales/ru/translation.json'

const fallbackLng = ['en']
const availableLanguages = ['en', 'ru']

const resources = {
   en: {
      translation: translationEN,
   },
   ru: {
      translation: translationRU,
   },
}

const lngDetector = new LanguageDetector(null, {
   lookupLocalStorage: 'LANGUAGE',
})

i18n
   .use(initReactI18next)
   .use(lngDetector)
   .init({
      resources,
      fallbackLng,

      detection: { checkWhitelist: true },

      debug: false,

      whitelist: availableLanguages,

      interpolation: { escapeValue: false },
   })

export const { t } = i18n

export const getCurrentLanguage = () => {
   if (i18n.language.includes('ru')) return 'ru'
   return 'en'
}

export const AVAILABLE_LANGUAGES = [
   { value: 'ru', label: 'Русский' },
   { value: 'en', label: 'English' },
]

export default i18n
