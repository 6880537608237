import { createSlice } from '@reduxjs/toolkit'
import {
   createLesson,
   deleteLesson,
   dragLesson,
   editLesson,
   getLessonById,
   getLessons,
} from './lessonsActions'

const initialState = {
   lesson: null,
   lessons: [],
   isLoading: false,
   count: 0,
   params: { page: 1, size: 0 },
}

const lessonsSlice = createSlice({
   name: 'lessons-slice',
   initialState,
   reducers: {
      clearLesson(state) {
         state.lesson = null
      },
      clearLessons(state) {
         state.lessons = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getLessons.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getLessons.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getLessons.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.lessons = payload.lessons
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(getLessonById.fulfilled, (state, { payload }) => {
            state.lesson = payload
         })
         .addCase(createLesson.fulfilled, (state, { payload }) => {
            state.lessons = payload
         })
         .addCase(editLesson.fulfilled, (state, { payload }) => {
            state.lessons = payload
         })
         .addCase(deleteLesson.fulfilled, (state, { payload: lessonId }) => {
            state.lessons = state.lessons.filter(({ id }) => id !== +lessonId)
         })
         .addCase(dragLesson.pending, (state, { meta: { arg } }) => {
            const newList = state.lessons.slice()
            newList.splice(arg.source, 1)
            newList.splice(arg.orderNum, 0, state.lessons[arg.source])
            state.lessons = newList
         })
         .addCase(dragLesson.fulfilled, () => {})
   },
})

export const { clearLesson, clearLessons } = lessonsSlice.actions

export default lessonsSlice
