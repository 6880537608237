import axiosInstance from '../config/axiosInstance'

export const getTasksRequest = (lessonId) => {
   return axiosInstance.get(`/lessons/${lessonId}/tasks`)
}

export const getTaskByIdRequest = (taskId) => {
   return axiosInstance.get(`/lessons/tasks/${taskId}`)
}

export const updateTaskRequest = (taskId, taskData) => {
   return axiosInstance.put(`/lessons/tasks/${taskId}`, taskData)
}

export const createTaskRequest = (lessonId, taskData) => {
   return axiosInstance.post(`/lessons/${lessonId}/tasks`, taskData)
}

export const getTaskAnswersRequest = (taskId) => {
   return axiosInstance.get(`/lessons/tasks/${taskId}/answers`)
}
export const deleteTaskRequest = (taskId) => {
   return axiosInstance.delete(`/lessons/tasks/${taskId}`)
}

export const approveTaskRequest = (taskData, taskAnswerId) => {
   return axiosInstance.post(`/lessons/tasks/answers/${taskAnswerId}`, {
      ...taskData,
      status: 'APPROVE',
   })
}

export const rejectTaskRequest = (taskData, taskAnswerId) => {
   return axiosInstance.post(`/lessons/tasks/answers/${taskAnswerId}`, {
      comment: taskData.comment,
      status: 'REJECT',
   })
}
