import axiosInstance from '../config/axiosInstance'

export const loginRequest = (signUpData) => {
   return axiosInstance.post(`/auth/authenticate`, signUpData)
}

export const forgotPasswordRequest = (email) => {
   return axiosInstance.post('/auth/forgotPassword', email)
}

export const resetPasswordRequest = (values) => {
   return axiosInstance.put(`/auth/updatePassword`, values)
}
