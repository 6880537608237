import { useEffect, useState } from 'react'

const useTheme = () => {
   const [theme, setTheme] = useState('light')

   useEffect(() => {
      const onThemeChange = ({ matches }) => {
         return setTheme(matches ? 'dark' : 'light')
      }

      const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
      onThemeChange({ matches: matchMedia.matches })

      matchMedia.addEventListener('change', onThemeChange)
      return () => {
         matchMedia.removeEventListener('change', onThemeChange)
      }
   }, [])

   return theme
}

export default useTheme
