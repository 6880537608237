import { Suspense, useEffect, useState } from 'react'
import { useStore } from 'react-redux'

import { CssBaseline, ThemeProvider } from '@mui/material'
import './assets/styles/App.css'
import { localStorageHelper } from './utils/helpers/localStorageHelper'
import { localStorageKeyNames, MAIN_ROUTES } from './utils/constants/general'
import Spinner from './components/UI/Loading/Spinner'
import { authActions } from './store/auth/authSlice'
import useTheme from './utils/hooks/useTheme'
import { createTheme } from './assets/styles/GlobalStyles'
import MainRoutes from './routes/MainRoutes'

function App() {
   const { dispatch } = useStore()
   const [isLoading, setIsLoading] = useState(true)

   const theme = useTheme()
   const muiTheme = createTheme(theme)

   useEffect(() => {
      const { pathname } = window.location

      const authData = localStorageHelper.getFromLocalStorage(
         localStorageKeyNames.LOGIN_USER_INFORMATION
      )

      if (authData && ['/login', '/sign-up', '/'].includes(pathname)) {
         dispatch(authActions.authLogin(authData))
         window.location = `${MAIN_ROUTES[authData.role].path}`
      }

      if (pathname === '/') window.location = '/login'

      setIsLoading(false)
   }, [])

   if (isLoading) return <Spinner size={55} left="50%" />
   return (
      <Suspense fallback={<Spinner size={55} left="50%" />}>
         <ThemeProvider theme={muiTheme}>
            <MainRoutes />
            <CssBaseline />
         </ThemeProvider>
      </Suspense>
   )
}

export default App
