import { createSlice } from '@reduxjs/toolkit'
import { convertTestData } from '../../utils/helpers/testHelper'
import {
   deleteTest,
   getStudentTestResults,
   getTestAnswers,
   getTestById,
   getTestAsStudent,
   getTestPreview,
   getTests,
   getStudentTestResultsAsInstructor,
} from './testActions'

const initialState = {
   isLoading: false,
   tests: [],
   test: null,
   testAnswers: { isActive: false, answersCount: 0, answers: [] },
   // testAnswer: { questions: [], student: {} },
   testAnswer: null,
}

const testSlice = createSlice({
   name: 'testSlice',
   initialState,
   reducers: {
      clearTests(state) {
         state.tests = []
      },
      clearTestAnswers(state) {
         state.testAnswers = { isActive: false, answersCount: 0, answers: [] }
         state.test = null
      },
      clearTest(state) {
         state.test = null
      },
      clearTestAnswer(state) {
         state.testAnswer = null
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getTests.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getTests.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getTests.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.tests = payload
         })
         .addCase(getTestPreview.fulfilled, (state, { payload }) => {
            state.test = payload
         })

         .addCase(getTestAsStudent.fulfilled, (state, { payload }) => {
            state.test = payload
         })

         .addCase(getTestAnswers.fulfilled, (state, { payload }) => {
            state.testAnswers = payload
         })

         .addCase(getStudentTestResults.fulfilled, (state, { payload }) => {
            state.test = payload
         })

         .addCase(deleteTest.fulfilled, (state, { payload: testId }) => {
            state.tests = state.tests.filter((test) => +test.testId !== +testId)
         })
         .addCase(getTestById.fulfilled, (state, { payload }) => {
            state.test = convertTestData(payload)
         })

         .addCase(getStudentTestResultsAsInstructor.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getStudentTestResultsAsInstructor.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(
            getStudentTestResultsAsInstructor.fulfilled,
            (state, { payload }) => {
               state.isLoading = false
               state.testAnswer = payload
            }
         )
   },
})

export const { clearTests, clearTestAnswers, clearTest, clearTestAnswer } =
   testSlice.actions

export default testSlice
