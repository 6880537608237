import axiosInstance from '../config/axiosInstance'
import { formatDate } from '../utils/helpers/dateHelper'

// ADMIN, INSTRUCTOR
export const getAnnouncementsRequest = (search, paginationParams) => {
   const params = { ...search, ...paginationParams }
   if (!params.groupId) {
      delete params.groupId
   }
   if (params.from) {
      params.from = formatDate(params.from, 'yyyy-MM-dd')
   }
   if (params.to) {
      params.to = formatDate(params.to, 'yyyy-MM-dd')
   }
   return axiosInstance.get('announcements', { params })
}

export const getAnnouncementByIdRequest = (announcementId) => {
   return axiosInstance.get(`announcements/${announcementId}`)
}

export const editAnnouncementRequest = (announcementId, data) => {
   return axiosInstance.put(`announcements/${announcementId}`, data)
}

export const deleteAnnouncementRequest = (announcementId) => {
   return axiosInstance.delete(`announcements/${announcementId}`)
}

export const createAnnouncementRequest = (announcement) => {
   return axiosInstance.post('announcements', announcement)
}

export const AnnouncementShowStatusChangeRequest = (
   announcementId,
   isShowAnnouncements
) => {
   return axiosInstance.put(
      `announcements/${announcementId}/activation?&active=${isShowAnnouncements}`
   )
}
