export const localStorageHelper = {
   getFromLocalStorage: (key) => {
      const data = localStorage.getItem(key)
      return data && JSON.parse(data)
   },

   setLocalStorage: (key, data) => {
      const json = JSON.stringify(data)
      localStorage.setItem(key, json)
   },

   removeLocalStorage: (key) => {
      localStorage.removeItem(key)
   },

   clearLocalStorage: () => {
      localStorage.clear()
   },

   getLanguage: (key) => {
      const language = localStorage.getItem(key)
      switch (language) {
         case 'ru-RU':
         case 'ru':
            return 'ru'

         case 'en':
            return 'en'
         default:
            return 'en'
      }
   },
}
