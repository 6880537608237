import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, CircularProgress } from '@mui/material'

const Spinner = ({ size, ...props }) => {
   return (
      <Container {...props}>
         <CircularProgress size={size} />
      </Container>
   )
}

export default Spinner

Spinner.propTypes = {
   size: PropTypes.number,
}
Spinner.defaultProps = {
   size: 40,
}

const Container = styled(Box)`
   position: fixed;
   width: 100%;
   height: 100%;
   display: grid;
   place-items: center;

   opacity: 0.7;
   top: 50%;
   left: ${({ left }) => left || '55%'};
   transform: translate(-50%, -50%);
`
