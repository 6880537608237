import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   addLessonPresentationRequest,
   getPresentationByIdRequest,
   getPresentationsRequest,
   editPresentationRequest,
   deletePresentationRequest,
} from '../../api/presentationService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getFileName } from '../../utils/constants/general'
import { getRequestError } from '../../utils/helpers/requestHelper'

// get presentations ROLE(INSTRUCTOR, STUDENT)
export const getPresentations = createAsyncThunk(
   'presentationsSlice/getPresentations',
   async ({ lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getPresentationsRequest(lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// add presentation ROLE(INSTRUCTOR)
export const addPresentation = createAsyncThunk(
   'presentationsSlice/addPresentation',
   async ({ lessonId, presentation, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await addLessonPresentationRequest(
            lessonId,
            presentation
         )
         notify(data.message)
         onClose()
         return data.presentations
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get presentation by id ROLE(INSTRUCTOR)
export const getPresentationById = createAsyncThunk(
   'presentationsSlice/getPresentationById',
   async ({ presentationId, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getPresentationByIdRequest(
            lessonId,
            presentationId
         )
         return { ...data, fileLink: getFileName(data.link), link: data.link }
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// edit presentation ROLE(INSTRUCTOR)
export const editPresentation = createAsyncThunk(
   'presentationsSlice/editPresentation',
   async (
      { presentation, lessonId, onClose, presentationId },
      { rejectWithValue }
   ) => {
      try {
         const { data } = await editPresentationRequest(
            lessonId,
            presentationId,
            presentation
         )
         notify(data.message)
         onClose()
         return data.presentations
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete presentation ROLE(INSTRUCTOR)
export const deletePresentation = createAsyncThunk(
   'presentationsSlice/deletePresentation',
   async ({ presentationId, lessonId, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await deletePresentationRequest(
            lessonId,
            presentationId
         )
         notify(data.message)
         onClose()
         return presentationId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
