import { parseISO } from 'date-fns'
import { formatDate } from './dateHelper'

/* eslint-disable no-param-reassign */
export const convertStudentTestResults = (test) => {
   test.dateOfPass = formatDate(parseISO(test.dateOfPassTheTest))
   test.name = test.testName
   test.questions.forEach((question) => {
      question.description = question.question
      question.options = question.options.map((option) => {
         return {
            description: option.option,
            true: option.status !== 'NOT_SELECTED',
            status: option.status,
         }
      })
   })

   return test
}

export const generateTestAnswer = (test) => {
   const emptyTest = {}
   emptyTest.name = test.name
   emptyTest.testId = test.testId

   emptyTest.answers = test.questions.map((question) => {
      const options = question.options.reduce((acc, opt) => {
         return { ...acc, [opt.optionId]: false }
      }, {})

      return {
         type: question.questionType,
         questionId: question.questionId,
         options,
      }
   })

   return emptyTest
}

export const convertTime = (time) => {
   if (`${time}`.length === 1) return `0${time}`
   return time
}

export const converTestAnswers = (test) => {
   const newTest = { testId: test.testId }
   newTest.testTime = `${convertTime(test.testTime.hours)}:${convertTime(
      test.testTime.minutes
   )}`

   const testAnswers = {}

   test.answers.forEach((answer) => {
      testAnswers[answer.questionId] = Object.keys(answer.options).filter(
         (key) => answer.options[key]
      )
   })

   newTest.answers = testAnswers
   return newTest
}

export const convertTestData = (data) => {
   const test = data
   test.timeToTakeTheTest = test.timeToTakeTheTest.slice(0, -3)

   test.questions = data.questions.map((question) => {
      return {
         ...question,
         id: Math.random(),
         options: makeObjectUnique(question.options),
      }
   })
   return test
}

export const makeObjectUnique = (array) => {
   return array.map((item) => ({ ...item, id: Math.random() }))
}
