import axios from 'axios'
import { BASE_URL, localStorageKeyNames } from '../utils/constants/general'
import { localStorageHelper } from '../utils/helpers/localStorageHelper'

const { removeLocalStorage, getFromLocalStorage } = localStorageHelper
const { LOGIN_USER_INFORMATION } = localStorageKeyNames

const authData = getFromLocalStorage(LOGIN_USER_INFORMATION)

const headers = { 'Content-Type': 'multipart/form-data' }

if (authData?.token) {
   headers.Authorization = `Bearer ${authData.token}`
}

const formDataInstance = axios.create({ baseURL: BASE_URL, headers })

formDataInstance.interceptors.response.use(
   (response) => {
      return Promise.resolve(response)
   },
   (error) => {
      if (error.response?.status === 401) {
         removeLocalStorage(LOGIN_USER_INFORMATION)
      }
      return Promise.reject(error)
   }
)
export default formDataInstance
