import axiosInstance from '../config/axiosInstance'

export const createGroupRequest = (courseData) => {
   return axiosInstance.post('groups', courseData)
}

export const getGroupsRequest = (params) => {
   return axiosInstance.get('groups', { params })
}

export const getGroupByIdRequest = (groupId) => {
   return axiosInstance.get(`groups/${groupId}`)
}

export const editGroupById = (groupId, groupData) => {
   return axiosInstance.put(`groups/${groupId}`, groupData)
}

export const deleteGroupById = (groupId) => {
   return axiosInstance.delete(`groups/${groupId}`)
}

export const getGroupStudentsRequest = (groupId) => {
   return axiosInstance.get(`groups/${groupId}/students`)
}
