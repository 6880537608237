import { createSlice } from '@reduxjs/toolkit'
import {
   getCourseById,
   getCourses,
   getCourseStudents,
   getCourseTeachers,
   getStudentsCourses,
} from './courseActions'

const initialState = {
   courses: [],
   students: [],
   teachers: [],
   course: { courseName: '', id: '' },
   isLoading: false,
   count: 0,
   params: { page: 1, size: 0 },
}
const coursesSlice = createSlice({
   name: 'courseSlice',
   initialState,
   reducers: {
      clearCourseStudents(state) {
         state.students = []
      },
      clearCourseTeachers(state) {
         state.teachers = []
      },
      clearCourses(state) {
         state.courses = []
      },
      clearCourse(state) {
         state.course = { courseName: '', id: '' }
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getCourses.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getCourses.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getCourses.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.courses = payload.courses
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(getStudentsCourses.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getStudentsCourses.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getStudentsCourses.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.courses = payload.courses
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(getCourseStudents.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getCourseStudents.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getCourseStudents.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.students = payload
         })

         .addCase(getCourseTeachers.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getCourseTeachers.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getCourseTeachers.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.teachers = payload
         })

         .addCase(getCourseById.fulfilled, (state, { payload }) => {
            state.course = payload
         })
   },
})

export const {
   clearCourseStudents,
   clearCourseTeachers,
   clearCourses,
   clearCourse,
   setIsLoading,
} = coursesSlice.actions

export default coursesSlice
