import { ReactComponent as FileIcon } from '../../assets/icons/materials/task-file.svg'
import { ReactComponent as VideoIcon } from '../../assets/icons/materials/video.svg'
import { ReactComponent as PresentationIcon } from '../../assets/icons/materials/presentation.svg'
import { ReactComponent as TestIcon } from '../../assets/icons/materials/test.svg'
import { ReactComponent as LinkIcon } from '../../assets/icons/materials/link.svg'
import { t } from '../../config/i18nextConfig'

export const BASE_URL =
   process.env.REACT_APP_BACKEND_URL || 'https://backend.peaksoft.school/api'

export const BASE_SOCKET_URL =
   process.env.REACT_APP_SOCKET_URL || 'https://backend.peaksoft.school'

export const ROLES = {
   ADMIN: 'ADMIN',
   INSTRUCTOR: 'INSTRUCTOR',
   STUDENT: 'STUDENT',
}

export const MAIN_ROUTES = {
   INSTRUCTOR: {
      path: '/instructor',
   },
   ADMIN: {
      path: '/admin',
   },
   STUDENT: {
      path: '/student',
   },
}

export const localStorageKeyNames = {
   LOGIN_USER_INFORMATION: 'LOGIN_USER_INFORMATION',
   LANGUAGE: 'LANGUAGE',
}

export const MODALS = {
   MODAL: 'modal',
   CREATE_GROUP_MODAL: 'CREATE_GROUP_MODAL',
   DELETE_GROUP_MODAL: 'DELETE_GROUP_MODAL',
   EDIT_GROUP_MODAL: 'EDIT_GROUP_MODAL',
   CREATE_ANNOUNCEMENT_MODAL: 'CREATE_ANNOUNCEMENT_MODAL',
   EDIT_ANNOUNCEMENT_MODAL: 'EDIT_ANNOUNCEMENT_MODAL',
   DELETE_ANNOUNCEMENT_MODAL: 'DELETE_ANNOUNCEMENT_MODAL',
   HIDE_ANNOUNCEMENT_MODAL: 'HIDE_ANNOUNCEMENT_MODAL',
   CREATE_COURSE_MODAL: 'CREATE_COURSE_MODAL',
   ASSIGN_TEACHER_MODAL: 'ASSIGN_TEACHER_MODAL',
   DELETE_COURSE_MODAL: 'DELETE_COURSE_MODAL',
   EDIT_COURSE_MODAL: 'EDIT_COURSE_MODAL',
   STUDENTS_FILTER_FORMAT_MODAL: 'STUDENTS_FILTER_FORMAT_MODAL',
   CREATE_STUDENT_MODAL: 'CREATE_STUDENT_MODAL',
   EDIT_STUDENT_MODAL: 'EDIT_STUDENT_MODAL',
   DELETE_STUDENT_MODAL: 'DELETE_STUDENT_MODAL',
   BLOCK_STUDENT_MODAL: 'BLOCK_STUDENT_MODAL',
   IMPORT_EXEL_MODAL: 'IMPORT_EXEL_MODAL',
   DELETE_COURSE_TEACHER: 'DELETE_COURSE_TEACHER',

   FORGOT_PASSWORD_MODAL: 'FORGOT_PASSWORD_MODAL',

   NOTIFICATION_MODAL: 'NOTIFICATION_MODAL',
   LOGOUT_MODAL: 'LOGOUT_MODAL',
}

export const TEACHERS_MODALS = {
   MODAL: 'modal',
   ADD_TEACHER_MODAL: 'ADD_TEACHER_MODAL',
   EDIT_TEACHER_MODAL: 'EDIT_TEACHER_MODAL',
   DELETE_TEACHER_MODAL: 'DELETE_TEACHER_MODAL',
   ADD_GROUP_TO_COURSE_MODALS: 'ADD_GROUP_TO_COURSE_MODALS',
   DELETE_GROUP_FROM_COURSE_MODAL: 'DELETE_GROUP_FROM_MODAL',
   RATING_DISTRIBUTOR_MODAL: 'RATING_DISTRIBUTOR_MODAL',
   CREATE_LESSON_MODAL: 'CREATE_LESSON_MODAL',
   EDIT_LESSON_MODAL: 'EDIT_LESSON_MODAL',
   DELETE_LESSON_MODAL: 'DELETE_LESSON_MODAL',
   ADD_VIDEO_MODAL: 'ADD_VIDEO_MODAL',
   DELETE_VIDEO_MODAL: 'DELETE_VIDEO_MODAL',
   EDIT_VIDEO_MODAL: 'EDIT_VIDEO_MODAL',
   VIDEO_VIEWER_MODAL: 'VIDEO_VIEWER_MODAL',
}

export const PRESENTATION_MODALS = {
   MODAL: 'modal',
   ADD_PRESENTATION_MODAL: 'ADD_PRESENTATION_MODAL',
   DELETE_PRESENTATION_MODAL: 'DELETE_PRESENTATION_MODAL',
   EDIT_PRESENTATION_MODAL: 'EDIT_PRESENTATION_MODAL',
   PRESENTATION_VIEWER_MODAL: 'PRESENTATION_VIEWER_MODAL',
}

export const LINK_MODALS = {
   MODAL: 'modal',
   CREATE_LINK_MODAL: 'CREATE_LINK_MODAL',
   DELETE_LINK_MODAL: 'DELETE_LINK_MODAL',
   UPDATE_LINK_MODAL: 'UPDATE_LINK_MODAL',
}

export const TASK_MODALS = {
   MODAL: 'modal',
   DELETE_TASK_MODAL: 'DELETE_TASK_ITEM_MODAL',
}

export const TEST_MODALS = {
   MODAL: 'modal',
   DELETE_TEST_MODAL: 'DELETE_TEST_MODAL',
   BLOCK_ANSWERS_MODAL: 'BLOCK_ANSWERS_MODAL',
   CHANGE_TIME_MODAL: 'CHANGE_TIME_MODAL',
   SHOW_STUDENT_ANSWERS: 'SHOW_STUDENT_ANSWERS',
}

export const RATING_MODALS = {
   MODAL: 'modal',
   ADD_EXAM: 'ADD_NEW_EXAM',
   DELETE_EXAM: 'DELETE_EXAM',
}

export const CALENDAR_MODALS = {
   MODAL: 'modal',
   CANCEL_EVENT_MODAL: 'CANCEL_EVENT_MODAL',
   DELETE_EVENT_MODAL: 'DELETE_EVENT_MODAL',
}

export const STUDY_FORMAT = [
   { id: '1', value: 'ONLINE', label: 'ONLINE' },
   { id: '2', value: 'OFFLINE', label: 'ONLINE' },
]

export const LESSON_TABS = [
   {
      link: 'video',
      label: t('lesson_items.video'),
      icon: <VideoIcon />,
   },
   {
      link: 'presentation',
      label: t('lesson_items.presentation'),
      icon: <PresentationIcon />,
   },
   {
      link: 'task',
      label: t('lesson_items.task'),
      icon: <FileIcon />,
   },
   {
      link: 'link',
      label: t('lesson_items.link'),
      icon: <LinkIcon />,
   },
   {
      link: 'test',
      label: t('lesson_items.test'),
      icon: <TestIcon />,
   },
]

export const LESSON_TABS_TRANSLATE = {
   video: t('lesson_items.video'),
   task: t('lesson_items.task'),
   test: t('lesson_items.test'),
   link: t('lesson_items.link'),
   presentation: t('lesson_items.presentation'),
}

export const TASK_TABS = [
   {
      link: 'waiting',
      label: t('task_tabs.waiting'),
   },
   {
      link: 'accepted',
      label: t('task_tabs.accepted'),
   },
   {
      link: 'notAccepted',
      label: t('task_tabs.notAccepted'),
   },
   {
      link: 'late',
      label: t('task_tabs.late'),
   },
   {
      label: t('task_tabs.notSubmitted'),
      link: 'notSubmitted',
   },
]

export const getYoutubeVideoId = (link) => {
   const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
   const match = link?.match(regExp)

   if (match && match[1]?.length === 11) {
      return match[1]
   }
   return ''
}

export const getYoutubeThumbnail = (link) => {
   const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
   const match = link?.match(regExp)

   if (match && match[1]?.length === 11) {
      return `https://img.youtube.com/vi/${match[1]}/mqdefault.jpg`
   }
   return 'error'

   // if (link) {
   //    const youtubeThumbnailId = link.substr(link.indexOf('=') + 1, 11)
   //    return `https://img.youtube.com/vi/${youtubeThumbnailId}/mqdefault.jpg`
   // }
   // return ''
}

export function getFileName(url) {
   if (url) {
      const m = url.toString().match(/.*\/(.+?)\./)
      if (m && m.length > 1) {
         return m[1]
      }
   }
   return ''
}

const getMaterials = (state) => {
   const data = {
      test: state.tests.test,
      task: state.tasks.instructorTask,
      course: state.courses.course,
      lesson: state.lessons.lesson,
   }
   return data
}
const optimizeMaterials = (oldState, newState) => {
   const test = oldState.test === newState.test
   const task = oldState.task === newState.task
   const course = oldState.course === newState.course
   const lesson = oldState.lesson === newState.lesson
   return test && task && course && lesson
}

export const getMaterialsFromStore = [getMaterials, optimizeMaterials]

export const generateTaskItem = (type, initValue = '') => {
   return { id: Math.random().toString(), type, value: initValue }
}

export const generateLinkItem = (type, link, displayText) => {
   return { id: Math.random().toString(), type, link, displayText }
}

export const generatePhotoItem = ({ type, value, file }) => {
   return { id: Math.random().toString(), type, value, file }
}

export const generateFileItem = ({ type, link, file, name }) => {
   return { id: Math.random().toString(), type, file, name, link }
}

export const isArrayEmpty = (arr) => arr.length === 0
