import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   createLinkRequest,
   deleteLinkRequest,
   getLinkByIdRequest,
   getLinksRequest,
   updateLinkRequest,
} from '../../api/linkService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'

// create link ROLE(INSTRUCTOR)
export const createLink = createAsyncThunk(
   'linkSlice/createLink',
   async ({ link, lessonId, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await createLinkRequest(lessonId, link)
         notify(data.message)
         onClose()
         return data.links
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create link ROLE(INSTRUCTOR)
export const getLinkById = createAsyncThunk(
   'linkSlice/getLinkById',
   async ({ linkId, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getLinkByIdRequest(lessonId, linkId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create link ROLE(INSTRUCTOR)
export const updateLink = createAsyncThunk(
   'linkSlice/updateLink',
   async ({ link, onClose, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await updateLinkRequest(lessonId, link.id, link)
         notify(data.message)
         onClose()
         return data.links
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create link ROLE(INSTRUCTOR)
export const deleteLink = createAsyncThunk(
   'linkSlice/deleteLink',
   async ({ linkId, onClose, lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await deleteLinkRequest(lessonId, linkId)
         notify(data.message)
         onClose()
         return linkId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create link ROLE(INSTRUCTOR, STUDENT)
export const getLinks = createAsyncThunk(
   'linkSlice/getLinks',
   async ({ lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getLinksRequest(lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
