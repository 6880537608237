import { createSlice } from '@reduxjs/toolkit'
import { getGroupById, getGroups, getGroupStudents } from './groupActions'

const initialState = {
   groups: [],
   group: { name: '', id: '' },
   groupStudents: [],
   isLoading: false,
   count: 0,
   params: { page: 1, size: 0 },
}
const groupsSlice = createSlice({
   name: 'groupsSlice',
   initialState,
   reducers: {
      clearGroupStudents(state) {
         state.groupStudents = []
      },
      clearGroups(state) {
         state.groups = []
      },
      clearGroup(state) {
         state.group = { name: '', id: '' }
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getGroups.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getGroups.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getGroups.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.groups = payload.groups
            state.count = payload.count
            state.params = payload.params
         })

         .addCase(getGroupStudents.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getGroupStudents.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getGroupStudents.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.groupStudents = payload
         })

         .addCase(getGroupById.fulfilled, (state, { payload }) => {
            state.group = payload
         })
   },
})

export const { clearGroupStudents, clearGroups, clearGroup } =
   groupsSlice.actions

export default groupsSlice
