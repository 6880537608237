import { createSlice } from '@reduxjs/toolkit'
import {
   addPresentation,
   deletePresentation,
   editPresentation,
   getPresentations,
} from './presentationActions'

const initialState = {
   presentations: [],
   isLoading: false,
}

const presentationsSlice = createSlice({
   name: 'presentationsSlice',
   initialState,
   reducers: {
      clearPresentations(state) {
         state.presentations = []
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getPresentations.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getPresentations.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getPresentations.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.presentations = payload
         })

         .addCase(addPresentation.fulfilled, (state, { payload }) => {
            state.presentations = payload
         })
         .addCase(editPresentation.fulfilled, (state, { payload }) => {
            state.presentations = payload
         })
         .addCase(deletePresentation.fulfilled, (state, { payload }) => {
            state.presentations = state.presentations.filter(
               (pres) => pres.presentationId !== +payload
            )
         })
   },
})

export const { clearPresentations } = presentationsSlice.actions

export default presentationsSlice
