import { createSlice } from '@reduxjs/toolkit'
import {
   deleteNotification,
   getNotifications,
   viewNotification,
} from './notificationActions'

const initialState = {
   notifications: {
      viewed: [],
      notViewed: [],
      notificationSettings: {
         webNotificationEnabled: false,
         emailNotificationEnabled: false,
      },
   },
}

const notificationSlice = createSlice({
   name: 'notificationSlice',
   initialState,
   reducers: {
      clearNotifications(state) {
         state.notifications = initialState.notifications
      },
      setNotifications(state, { payload: notifications }) {
         const nots = { ...state.notifications }
         notifications.forEach((not) => {
            if (not.viewed) return nots.viewed.push(not)
            return nots.notViewed.push(not)
         })

         state.notifications = nots
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getNotifications.fulfilled, (state, { payload }) => {
            state.notifications = payload
         })

         .addCase(viewNotification.fulfilled, (state, { payload }) => {
            state.notifications = payload
         })

         .addCase(deleteNotification.fulfilled, (state, { payload }) => {
            state.notifications[payload.type] = state.notifications[
               payload.type
            ].filter((not) => not.id !== payload.notId)
         })
   },
})

export const { clearNotifications, setNotifications } =
   notificationSlice.actions

export default notificationSlice
