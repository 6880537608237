import axiosInstance from '../config/axiosInstance'

export const getVideosRequest = (lessonId) => {
   return axiosInstance.get(`/lessons/${lessonId}/videos`)
}

export const addLessonVideoRequest = (lessonId, video) => {
   return axiosInstance.post(`/lessons/${lessonId}/videos`, video)
}

export const getVideoByIdRequest = (lessonId, videoId) => {
   return axiosInstance.get(`/lessons/${lessonId}/videos/${videoId}`)
}

export const editVideoRequest = (lessonId, videoId, video) => {
   return axiosInstance.put(`/lessons/${lessonId}/videos/${videoId}`, video)
}

export const deleteVideoRequest = (lessonId, videoId) => {
   return axiosInstance.delete(`/lessons/${lessonId}/videos/${videoId}`)
}
