import { getCurrentLanguage } from '../../config/i18nextConfig'

export const syncronizeRatingData = (rating) => {
   try {
      const isEn = getCurrentLanguage() === 'en'

      const allTasks = []

      const lessons = rating.lessons.map((lesson) => {
         if (!lesson.tasks.length && !lesson.tests.length) {
            allTasks.push({ taskId: 99999, name: '---' })
            return { ...lesson, items: [{ taskId: 99999, name: '---' }] }
         }

         const lessonTasks = lesson.tasks.map((task) => ({
            ...task,
            lessonId: lesson.lessonId,
         }))

         const lessonTests = lesson.tests.map((test) => ({
            ...test,
            lessonId: lesson.lessonId,
         }))
         allTasks.push(...lessonTasks, ...lessonTests)
         return { ...lesson, items: [...lesson.tasks, ...lesson.tests] }
      })

      const exams = rating.exams.map((exam) => exam)

      allTasks.push(...exams)

      const students = []

      rating.students.forEach((student, index) => {
         allTasks.forEach((task) => {
            students[index] = {
               ...students[index],
               items: students[index]?.items || [],
            }
            if (typeof task?.taskId === 'number') {
               const indexOfTask = student.tasks.findIndex(
                  (studentTask) => studentTask.taskId === task.taskId
               )
               if (indexOfTask !== -1) {
                  const currentTask = student.tasks[indexOfTask]
                  students[index] = {
                     ...students[index],
                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     items: [
                        ...(students[index]?.items || []),
                        {
                           ...currentTask,
                           lessonId: task.lessonId,
                           studentId: student.studentId,
                        },
                     ],
                  }
               } else {
                  students[index] = {
                     ...students[index],

                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     items: [
                        ...(students[index]?.items || []),
                        { mark: '0', taskId: task.taskId },
                     ],
                  }
               }
            }
            if (typeof task?.testId === 'number') {
               // TEST
               const indexOfTest = student.tests.findIndex(
                  (studentTask) => studentTask.testId === task.testId
               )

               if (indexOfTest !== -1) {
                  const currentTask = student.tests[indexOfTest]
                  students[index] = {
                     ...students[index],

                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     items: [
                        ...(students[index]?.items || []),
                        {
                           ...currentTask,
                           mark: `${currentTask.points.pointsReceived} ${
                              isEn ? 'out of' : 'из'
                           }  ${currentTask.points.maxPoints}`,
                           lessonId: task.lessonId,
                           studentId: student.studentId,
                        },
                     ],
                  }
               } else {
                  students[index] = {
                     ...students[index],

                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     items: [
                        ...(students[index]?.items || []),
                        { mark: '0', taskId: task.testId },
                     ],
                  }
               }
            }

            if (typeof task?.examId === 'number') {
               const indexOfExam = student.exams.findIndex(
                  (studentExam) => studentExam.examId === task.examId
               )

               if (indexOfExam !== -1) {
                  const currentExam = student.exams[indexOfExam]
                  students[index] = {
                     ...students[index],

                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     exams: [
                        ...(students[index]?.exams || []),
                        {
                           ...currentExam,
                           mark: currentExam?.mark || 100,
                        },
                     ],
                  }
               } else {
                  students[index] = {
                     ...students[index],

                     studentFullName: student.studentFullName,
                     studentId: student.studentId,
                     total: student.total,

                     exams: [
                        ...(students[index]?.exams || []),
                        { mark: 0, examId: task.examId }, // mark: 0 === default value
                     ],
                  }
               }
            }
            return null
         })
      })

      return { lessons, students, exams: rating?.exams }
   } catch (e) {
      console.log(e)
      return {
         students: [],
         lessons: [],
         exams: [],
      }
   }
}
