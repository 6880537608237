import format from 'date-fns/format'

export const formatDate = (date, dateFormat = 'MM.dd.yy, hh:mm') => {
   return format(date, dateFormat)
}

export const getTimeZone = () => {
   const timezoneOffset = new Date().getTimezoneOffset()
   const offset = Math.abs(timezoneOffset)
   const offsetOperator = timezoneOffset < 0 ? '+' : '-'
   const offsetHours = (offset / 60).toString().padStart(2, '0')
   const offsetMinutes = (offset % 60).toString().padStart(2, '0')

   return `UTC${offsetOperator}${offsetHours}:${offsetMinutes}`
}

export const DAYS_OF_WEEK = [
   { label: 'Пн', active: false, index: 1 },
   { label: 'ВТ', active: false, index: 2 },
   { label: 'СР', active: false, index: 3 },
   { label: 'ЧТ', active: false, index: 4 },
   { label: 'ПТ', active: false, index: 5 },
   { label: 'СБ', active: false, index: 6 },
   { label: 'ВС', active: false, index: 0 },
]

export const getDaysBetweenDates = (start, end, days) => {
   let startDate = new Date(start)
   const endDate = new Date(end)

   const daysBetween = []

   while (startDate < endDate) {
      const nextDate = startDate.setDate(startDate.getDate() + 1)

      const nextDaysDate = new Date(nextDate)
      const dayIndex = nextDaysDate.getDay()

      if (days[dayIndex]) {
         daysBetween.push(nextDaysDate)
      }

      startDate = new Date(nextDate)
   }

   return daysBetween
}

const get2DigitTime = (date, type) => {
   if (type === 'HOUR') {
      let startHour = date.getHours()
      if (startHour < 10) startHour = `0${startHour}`

      return startHour
   }

   let startMinutes = date.getMinutes()
   if (startMinutes < 10) startMinutes = `0${startMinutes}`

   return startMinutes
}

export const getHourAndMinute = (date) => {
   const hour = get2DigitTime(date, 'HOUR')
   const minute = get2DigitTime(date, 'MINUTES')

   return `${hour}:${minute}`
}
