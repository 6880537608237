/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
   getStudentTaskByInstructorRequest,
   getStudentTaskRequest,
   sendStudentTaskRequest,
   updateStudentTaskRequest,
} from '../../api/studentsService'
import {
   getTasksRequest,
   getTaskByIdRequest,
   updateTaskRequest,
   createTaskRequest,
   getTaskAnswersRequest,
   deleteTaskRequest,
   rejectTaskRequest,
   approveTaskRequest,
} from '../../api/taskService'
import { notify } from '../../components/UI/Notifications/snackActions'
import { getRequestError } from '../../utils/helpers/requestHelper'
import { t } from '../../config/i18nextConfig'

// get tasks ROLE(INSTRUCTOR)
export const getTasks = createAsyncThunk(
   'taskSlice/get-tasks',
   async ({ lessonId }, { rejectWithValue }) => {
      try {
         const { data } = await getTasksRequest(lessonId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get task by id ROLE(INSTRUCTOR, STUDENT)
export const getTaskById = createAsyncThunk(
   'taskSlice/get-task',
   async ({ taskId }, { rejectWithValue }) => {
      try {
         const { data } = await getTaskByIdRequest(taskId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// create task ROLE(INSTRUCTOR)
export const createTask = createAsyncThunk(
   'taskSlice/create-task',
   async ({ lessonId, task, onConfirm }, { rejectWithValue }) => {
      try {
         const { data } = await createTaskRequest(lessonId, task)
         notify(data.message)
         onConfirm()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update task ROLE(INSTRUCTOR)
export const updateTask = createAsyncThunk(
   'taskSlice/update-task',
   async ({ taskId, task, onConfirm }, { rejectWithValue }) => {
      try {
         const { data } = await updateTaskRequest(taskId, task)
         notify(data.message)
         onConfirm()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get task answers ROLE(INSTRUCTOR)
export const getTaskAnswers = createAsyncThunk(
   'taskSlice/task-answers',
   async ({ taskId }, { rejectWithValue }) => {
      try {
         const { data } = await getTaskAnswersRequest(taskId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// delete task ROLE(INSTRUCTOR)
export const deleteTask = createAsyncThunk(
   'taskSlice/delete-task',
   async ({ taskId, onClose }, { rejectWithValue }) => {
      try {
         const { data } = await deleteTaskRequest(taskId)
         notify(data.message)
         onClose()
         return taskId
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// approve student task ROLE(INSTRUCTOR)
export const approveStudentTask = createAsyncThunk(
   'taskSlice/approve-task',
   async ({ answer, taskAnswerId, navigateToTask }, { rejectWithValue }) => {
      try {
         const { data } = await approveTaskRequest(answer, taskAnswerId)
         notify(data.message)
         return navigateToTask()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// reject student task ROLE(INSTRUCTOR)
export const rejectStudentTask = createAsyncThunk(
   'taskSlice/reject-task',
   async ({ answer, taskAnswerId, navigateToTask }, { rejectWithValue }) => {
      try {
         const { data } = await rejectTaskRequest(answer, taskAnswerId)
         notify(data.message)
         return navigateToTask()
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get student answer as instructor ROLE(INSTRUCTOR)
export const getStudentAnswerAsInstructor = createAsyncThunk(
   'taskSlice/get-student-task-by-instructor',
   async ({ taskAnswerId }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentTaskByInstructorRequest(taskAnswerId)
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// send student answer ROLE(STUDENT)
export const sendStudentAnswer = createAsyncThunk(
   'taskSlice/send-student-task',
   async ({ navigateToTasks, task, taskId }, { rejectWithValue }) => {
      try {
         const { data } = await sendStudentTaskRequest(task, taskId)
         notify(t('task.student.task_answer'))
         navigateToTasks()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// update student answer ROLE(STUDENT)
export const updateStudentTask = createAsyncThunk(
   'taskSlice/update-student-task',
   async ({ navigateToTask, task, taskId }, { rejectWithValue }) => {
      try {
         const { data } = await updateStudentTaskRequest(taskId, task)
         // notify(`Задание успешно отправлено!`)
         notify(t('task.student.task_answer'))
         navigateToTask()
         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)

// get student answer ROLE(STUDENT)
export const getStudentTask = createAsyncThunk(
   'taskSlice/get-student-task',
   async ({ taskId, navigateToCreateTask }, { rejectWithValue }) => {
      try {
         const { data } = await getStudentTaskRequest(taskId)
         console.log(data)
         if (!data.answer) navigateToCreateTask()

         return data
      } catch (error) {
         return rejectWithValue(getRequestError(error))
      }
   }
)
