import axiosInstance from '../config/axiosInstance'

// link requests
export const getLinksRequest = (lessonId) => {
   return axiosInstance.get(`/lessons/${lessonId}/links`)
}

export const createLinkRequest = (lessonId, link) => {
   return axiosInstance.post(`/lessons/${lessonId}/links`, link)
}

export const getLinkByIdRequest = (lessonId, linkId) => {
   return axiosInstance.get(`/lessons/${lessonId}/links/${linkId}`)
}

export const deleteLinkRequest = (lessonId, linkId) => {
   return axiosInstance.delete(`/lessons/${lessonId}/links/${linkId}`)
}

export const updateLinkRequest = (lessonId, linkId, linkData) => {
   return axiosInstance.put(`/lessons/${lessonId}/links/${linkId}`, linkData)
}
