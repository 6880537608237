import axiosInstance from '../config/axiosInstance'

export const getTestsRequest = (lessonId) => {
   return axiosInstance.get(`/lessons/${lessonId}/tests`)
}

export const getTestByIdRequest = (testId) => {
   return axiosInstance.get(`/lessons/tests/${testId}`)
}

export const createTestRequest = (lessonId, test) => {
   return axiosInstance.post(`/lessons/tests/${lessonId}`, test)
}

export const updateTestRequest = (testId, test) => {
   return axiosInstance.put(`/lessons/tests/${testId}`, test)
}

export const deleteTestRequest = (testId) => {
   return axiosInstance.delete(`/lessons/tests/${testId}`)
}

export const getTestAnswersRequest = (testId) => {
   return axiosInstance.get(`/lessons/tests/${testId}/answers`)
}

export const blockTestAnswersRequest = (testId, message) => {
   return axiosInstance.put(`/lessons/tests/${testId}/activation`, message)
}

export const getStudentAnswerRequest = (testId, studentId) => {
   return axiosInstance.get(`/lessons/tests/${testId}/answers/${studentId}`)
}

// @ROLE(STUDENT)
// IN PROCESS...
export const getStudentAnswerByStudentRequest = (testId) => {
   return axiosInstance.get(`/lessons/students/tests/${testId}/answer`)
}

// GET TEST BY STUDENT
export const getTestAsStudentRequest = (testId) => {
   return axiosInstance.get(`/lessons/students/tests/${testId}`)
}

//
export const passTestRequest = (testData) => {
   return axiosInstance.post(`/lessons/students/tests/pass`, testData)
}
